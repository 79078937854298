import React, { useState, useEffect } from 'react';
import './SupportAdmin.css';  // Updated CSS filename

const statusOptions = ['To Do', 'In Progress', 'Done'];
const priorityOptions = ['Critical', 'Medium', 'Low'];

const statusColors = {
  'To Do': 'gray',
  'In Progress': 'blue',
  'Done': 'green'
};

const priorityColors = {
  Critical: 'red',
  Medium: 'orange',
  Low: 'yellow'
};

const SupportAdmin = () => {
  const [tickets, setTickets] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  // Fetch tickets
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch('https://api.rfro-1000km.com/support-tickets');
        if (response.ok) {
          const data = await response.json();
          setTickets(data);
        } else {
          setError('Failed to load tickets.');
        }
      } catch {
        setError('An error occurred while fetching tickets.');
      }
    };

    fetchTickets();
  }, []);

  // Update a ticket
  const updateTicket = async (id, updatedTicket) => {
    try {
      const response = await fetch(`https://api.rfro-1000km.com/support-tickets/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedTicket),
      });

      if (response.ok) {
        const data = await response.json();
        setTickets(tickets.map(ticket => (ticket.id === id ? data : ticket)));
        setIsEditing(false);
        setCurrentTicket(null);
      } else {
        setError('Failed to update ticket.');
      }
    } catch {
      setError('An error occurred while updating the ticket.');
    }
  };

  // Delete a ticket
  const deleteTicket = async (id) => {
    try {
      const response = await fetch(`https://api.rfro-1000km.com/support-tickets/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setTickets(tickets.filter(ticket => ticket.id !== id));
      } else {
        setError('Failed to delete ticket.');
      }
    } catch {
      setError('An error occurred while deleting the ticket.');
    }
  };

  const handleEditClick = (ticket) => {
    setCurrentTicket(ticket);
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (currentTicket) {
      updateTicket(currentTicket.id, currentTicket);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentTicket({ ...currentTicket, [name]: value });
  };

  return (
    <div className="support-admin">
      <h1>Support Management</h1>
      {error && <div className="error-message">{error}</div>}

      <div className="ticket-table">
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Contact Method</th>
              <th>Discord Username</th>
              <th>Issue Type</th>
              <th>Additional Info</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map(ticket => (
              <tr key={ticket.id}>
                <td>{ticket.first_name}</td>
                <td>{ticket.contact_method}</td>
                <td>{ticket.discord_username}</td>
                <td>{ticket.issue_type}</td>
                <td>{ticket.additional_info}</td>
                <td style={{ backgroundColor: statusColors[ticket.status] || 'gray' }}>
                  {ticket.status || 'Not Set'}
                </td>
                <td style={{ backgroundColor: priorityColors[ticket.priority] || 'gray' }}>
                  {ticket.priority || 'Not Set'}
                </td>
                <td>
                  <button className="support-edit-button" onClick={() => handleEditClick(ticket)}>Edit</button>
                  <button className="support-delete-button" onClick={() => deleteTicket(ticket.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditing && currentTicket && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Ticket</h2>
            <label>
              Status
              <select
                name="status"
                value={currentTicket.status || ''}
                onChange={handleInputChange}
              >
                {statusOptions.map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            </label>
            <label>
              Priority
              <select
                name="priority"
                value={currentTicket.priority || ''}
                onChange={handleInputChange}
              >
                {priorityOptions.map(priority => (
                  <option key={priority} value={priority}>{priority}</option>
                ))}
              </select>
            </label>
            <button className="support-save-button" onClick={handleSaveClick}>Save</button>
            <button className="support-cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportAdmin;