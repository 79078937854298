import React from 'react';
import './StreamSelector.css';

const StreamSelector = ({ onStreamChange, selectedStream }) => {
  const streams = [
    { id: 'en', name: 'English', platform: 'twitch', channel: 'tunedtv_official' },
    { id: 'fr', name: 'French', platform: 'twitch', channel: 'racestarttv' }
  ];

  const handleStreamSelect = (stream) => {
    onStreamChange(stream);
  };

  return (
    <div className="stream-selector">
      {streams.map((stream) => (
        <button
          key={stream.id}
          className={`stream-button ${selectedStream.id === stream.id ? 'active' : ''}`}
          onClick={() => handleStreamSelect(stream)}
        >
          <img
            src={`/${stream.id}.png`} // You will need to replace with correct flag paths
            alt={`${stream.name} flag`}
            className="stream-flag"
          />
        </button>
      ))}
    </div>
  );
};

export default StreamSelector;