// src/pages/RaceDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './RaceDetails.css';

const RaceDetails = () => {
  const { id } = useParams();
  const [roundDetails, setRoundDetails] = useState(null);

  useEffect(() => {
    const fetchRoundDetails = async () => {
      try {
        const response = await axios.get(`https://api.rfro-1000km.com/rounds/${id}`);
        setRoundDetails(response.data);
      } catch (error) {
        console.error('Error fetching round details:', error);
      }
    };

    fetchRoundDetails();
  }, [id]);

  if (!roundDetails) {
    return <div>Loading...</div>;
  }

  const renderLink = (link, text) => {
    if (link === '#') {
      return <span className="link-unavailable">{text} Unavailable</span>;
    }
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  return (
    <div className="race-details-container">
      <h1>{roundDetails.name}</h1>
      <img src={roundDetails.image_url} alt={roundDetails.name} className="race-image" />
      <div className="race-info">
        <h2>Location:</h2>
        <p>{roundDetails.location}</p>
        <h2>Date:</h2>
        <p>{new Date(roundDetails.date).toLocaleDateString()}</p>
        <h2>Number of Laps:</h2>
        <p>{roundDetails.number_of_laps}</p>
        <h2>Results:</h2>
        <ul>
          <li>{renderLink(roundDetails.qualifying_results_link, 'Qualifying Results')}</li>
          <li>{renderLink(roundDetails.race_results_link, 'Race Results')}</li>
        </ul>
        <h2>VOD Links:</h2>
        <ul>
          {Object.entries(roundDetails.vod_links).map(([language, link]) => (
            <li key={language}>{renderLink(link, `${language} VOD`)}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RaceDetails;