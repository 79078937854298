import React from 'react';
import ModpackCard from '../components/ModpackCard';
import '../App.css';
import './Modpack.css';

const Modpack = () => (
  <div className="container">
    <h1>Modpack</h1>
    <ModpackCard justify-content="center"/>
  </div>
);

export default Modpack;