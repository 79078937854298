import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Switch from 'react-switch'; // Import react-switch
import { FaHome, FaCalendarAlt, FaBroadcastTower, FaBars, FaTimes, FaChevronRight, FaArrowLeft, FaCog, FaInfoCircle, FaServer, FaTools, FaBalanceScale, FaPoll, FaPenFancy, FaPalette, FaQuestionCircle, FaUser, FaKey, FaDiscord, FaTv, FaExternalLinkAlt } from 'react-icons/fa';
import { FaUserGroup } from "react-icons/fa6";
import { GiPodium, GiPadlock } from "react-icons/gi";
import { IoTicket } from "react-icons/io5";
import { CiLogout, CiUser } from "react-icons/ci";
import { MdOutlineSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";
import { useAuth } from '../context/AuthContext'; // Import the custom hook to use AuthContext
import './Navbar.css';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState('');
  const [darkMode, setDarkMode] = useState(true); // State to track dark mode, default is on
  const dropdownRef = useRef(null);
  const [isRoundLive, setIsRoundLive] = useState(false); // State to track if a round is live
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth(); // Use AuthContext to manage authentication

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
    setSubmenuOpen(''); // Reset submenu when toggling main menu
  };

  const handleSubmenuToggle = (submenu) => {
    setSubmenuOpen(submenuOpen === submenu ? '' : submenu);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
      setSubmenuOpen(''); // Reset submenu when clicking outside
    }
  };

  useEffect(() => {
    // Handle click outside for dropdown
    document.addEventListener('mousedown', handleClickOutside);
  
    const checkRoundStatus = async () => {
      try {
        const response = await fetch('https://api.rfro-1000km.com/rounds');
        const rounds = await response.json();
  
        const currentTime = new Date();
        const gmtCurrentTime = new Date(currentTime.toUTCString()); // Convert to GMT
  
        rounds.forEach((round) => {
          const roundDate = new Date(`${round.date}T11:05:00Z`);
          const roundEndTime = new Date(`${round.date}T17:00:00Z`);
  
          if (gmtCurrentTime >= roundDate && gmtCurrentTime <= roundEndTime) {
            setIsRoundLive(true);
          }
        });
      } catch (error) {
        console.error('Error fetching rounds:', error);
      }
    };
  
    checkRoundStatus(); // Initial check
    const interval = setInterval(checkRoundStatus, 60000); // Check every minute
  
    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Remove event listener
      clearInterval(interval); // Clear interval
    };
  }, []);

  const handleLoginRedirect = () => {
    setDropdownOpen(false); // Close dropdown menu after login redirect
    navigate('/login'); // Redirect to login page
  };

  const handleAdminRedirect = () => {
    if (isAuthenticated) {
      navigate('/admin');
    } else {
      handleLoginRedirect();
    }
  };

  const handleDarkModeChange = (checked) => {
    setDarkMode(checked);
    if (!checked) {
      window.location.href = 'https://pbs.twimg.com/media/EgKq8L5WsAAUyOe.png'; // Redirect if dark mode is turned off
    }
  };

  return (
    <div className="navbar-container">
      <Link to="/" className="nav-item">
        <FaHome size={24} />
      </Link>
      <Link to="/schedule" className="nav-item">
        <FaCalendarAlt size={24} />
      </Link>
      <Link to="/teams" className="nav-item">
        <FaUserGroup size={24} />
      </Link>
      <Link to="/results" className="nav-item">
        <FaPoll size={24} />
      </Link>
      <Link to="/live" className="nav-item">
        <FaBroadcastTower size={24} className={isRoundLive ? 'flashing' : ''} />
      </Link>

      <div className="nav-item" onClick={handleDropdownToggle}>
        {dropdownOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>

      <div className={`dropdown ${dropdownOpen ? 'open' : ''}`} ref={dropdownRef}>
        {/* Settings Submenu */}
        {submenuOpen === 'settings' && (
          <>
            <div className="nav-back-button" onClick={() => setSubmenuOpen('')}>
              <FaArrowLeft />
              <span>Back</span>
            </div>
            <div className="dropdown-item">
              <span className="dropdown-label">Dark Mode</span>
              <Switch 
                checked={darkMode} 
                onChange={handleDarkModeChange} 
                onColor="#86d3ff"
                offColor="#ff5c5c"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
          </>
        )}

        {/* Championship Submenu */}
        {submenuOpen === 'championship' && (
          <>
            <div className="nav-back-button" onClick={() => setSubmenuOpen('')}>
              <FaArrowLeft />
              <span>Back</span>
            </div>
            <Link to="/schedule" className="dropdown-item">
              <FaCalendarAlt />
              <span className="dropdown-label">Calendar</span>
            </Link>
            <Link to="/results" className="dropdown-item">
              <FaPoll />
              <span className="dropdown-label">Results</span>
            </Link>
            <Link to="/teams" className="dropdown-item">
              <FaUserGroup />
              <span className="dropdown-label">Teams</span>
            </Link>
          </>
        )}

        {/* Info Submenu */}
        {submenuOpen === 'info' && (
          <>
            <div className="nav-back-button" onClick={() => setSubmenuOpen('')}>
              <FaArrowLeft />
              <span>Back</span>
            </div>
            <Link to="/servers" className="dropdown-item">
              <FaServer />
              <span className="dropdown-label">Servers</span>
            </Link>
            <Link to="/modpack" className="dropdown-item">
              <FaTools />
              <span className="dropdown-label">Modpack</span>
            </Link>
            <Link to="/rules" className="dropdown-item">
              <FaBalanceScale />
              <span className="dropdown-label">Rules</span>
            </Link>
            <Link to="/skins" className="dropdown-item">
              <FaPalette />
              <span className="dropdown-label">Skins</span>
            </Link>
            <Link to="/blog" className="dropdown-item">
              <FaPenFancy />
              <span className="dropdown-label">Blog</span>
            </Link>
          </>
        )}

        {/* Help Submenu */}
        {submenuOpen === 'help' && (
          <>
            <div className="nav-back-button" onClick={() => setSubmenuOpen('')}>
              <FaArrowLeft />
              <span>Back</span>
            </div>
            <Link to="/support" className="dropdown-item">
              <IoTicket />
              <span className="dropdown-label">Support</span>
            </Link>
            <Link to="https://discord.gg/3QAeEctZe3" className="dropdown-item" target="_blank">
              <FaDiscord />
              <span className="dropdown-label">Join the Discord</span>
            </Link>
            <Link to="https://stats.uptimerobot.com/LiVFbFucCb" className="dropdown-item" target="_blank">
              <MdOutlineSignalWifiStatusbarConnectedNoInternet4 />
              <span className="dropdown-label">Status</span>
            </Link>
          </>
        )}

        {/* User Submenu */}
        {submenuOpen === 'user' && (
          <>
            <div className="nav-back-button" onClick={() => setSubmenuOpen('')}>
              <FaArrowLeft />
              <span>Back</span>
            </div>
            {!isAuthenticated ? (
              <>
                <div className="dropdown-item" onClick={handleLoginRedirect}>
                  <FaKey />
                  <span className="dropdown-label">Login/Register</span>
                </div>
              </>
            ) : (
              <>
                <div className="dropdown-item" onClick={handleAdminRedirect}>
                  <GiPadlock color='red'/>
                  <span className="dropdown-label">Admin Panel</span>
                </div>
                <Link to="/profile" className="dropdown-item">
                  <CiUser />
                  <span className="dropdown-label">Profile</span>
                </Link>
                <div className="dropdown-item" onClick={logout}>
                  <CiLogout />
                  <span className="dropdown-label">Logout</span>
                </div>
              </>
            )}
          </>
        )}

        {/* Main Options */}
        {!submenuOpen && (
          <>
            <div className="dropdown-item" onClick={() => handleSubmenuToggle('user')}>
              <FaUser />
              <span className="dropdown-label">User</span>
              <FaChevronRight />
            </div>
            <div className="dropdown-item" onClick={() => handleSubmenuToggle('settings')}>
              <FaCog />
              <span className="dropdown-label">Settings</span>
              <FaChevronRight />
            </div>
            <div className="dropdown-item" onClick={() => handleSubmenuToggle('championship')}>
              <GiPodium />
              <span className="dropdown-label">Championship</span>
              <FaChevronRight />
            </div>
            <div className="dropdown-item" onClick={() => handleSubmenuToggle('info')}>
              <FaInfoCircle />
              <span className="dropdown-label">Info</span>
              <FaChevronRight />
            </div>
            <a href="/wip" className="dropdown-item">
              <FaTv />
              <span className="dropdown-label">Video Content</span>
              <FaExternalLinkAlt />
            </a>
            <div className="dropdown-item" onClick={() => handleSubmenuToggle('help')}>
              <FaQuestionCircle />
              <span className="dropdown-label">Help</span>
              <FaChevronRight />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;