import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BlogAdmin.css';  // Ensure to import the CSS file

const BlogAdmin = () => {
    const [formData, setFormData] = useState({
        id: null,
        title: '',
        subheading: '',
        thumbnail: '',
        author: '',
        content: '',
        main_image: '',
        tags: '',
        status: 'draft',
        date_posted: ''
    });

    const [posts, setPosts] = useState([]);
    
    const fetchPosts = useCallback(async () => {
        try {
            const response = await axios.get('https://api.rfro-1000km.com/blog');
            setPosts(response.data);
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        }
    }, []);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleContentChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            content: value
        }));
    };

    const handleStatusChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            status: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const tagsArray = formData.tags.split(',').map(tag => tag.trim());
        const formattedData = {
            ...formData,
            tags: tagsArray
        };
        console.log('Submit Data:', formattedData);
        try {
            if (formData.id) {
                await axios.put(`https://api.rfro-1000km.com/blog/${formData.id}`, formattedData);
            } else {
                await axios.post('https://api.rfro-1000km.com/blog', formattedData);
            }
            fetchPosts();
            resetForm();
        } catch (error) {
            console.error('Error submitting blog post:', error);
        }
    };

    const handleEdit = (post) => {
        setFormData({
            id: post.id,
            title: post.title || '',
            subheading: post.subheading || '',
            thumbnail: post.thumbnail || '',
            author: post.author || '',
            content: post.content || '',
            main_image: post.main_image || '',
            tags: (post.tags || []).join(', '),
            status: post.status || 'draft',
            date_posted: post.date_posted || ''
        });
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await axios.delete(`https://api.rfro-1000km.com/blog/${id}`);
                fetchPosts();
            } catch (error) {
                console.error('Error deleting blog post:', error);
            }
        }
    };

    const resetForm = () => {
        setFormData({
            id: null,
            title: '',
            subheading: '',
            thumbnail: '',
            author: '',
            content: '',
            main_image: '',
            tags: '',
            status: 'draft',
            date_posted: ''
        });
    };

    return (
        <div className="blog-admin">
            <h1>{formData.id ? 'Edit Post' : 'Create Post'}</h1>
            <form className="blog-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="subheading"
                    placeholder="Subheading"
                    value={formData.subheading}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="thumbnail"
                    placeholder="Thumbnail URL"
                    value={formData.thumbnail}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="author"
                    placeholder="Author"
                    value={formData.author}
                    onChange={handleInputChange}
                    required
                />
                <ReactQuill
                    value={formData.content}
                    onChange={handleContentChange}
                />
                <input
                    type="text"
                    name="main_image"
                    placeholder="Main Image URL"
                    value={formData.main_image}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="tags"
                    placeholder="Tags (comma separated)"
                    value={formData.tags}
                    onChange={handleInputChange}
                />
                <select className="status" name="status" value={formData.status} onChange={handleStatusChange}>
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                </select>
                <div className="button-group">
                    <button type="submit" className="blog-submit">
                        {formData.id ? 'Update Post' : 'Create Post'}
                    </button>
                    {formData.id && (
                        <button className="cancel-button" type="button" onClick={resetForm}>
                            Cancel
                        </button>
                    )}
                </div>
            </form>
            <table className="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Subheading</th>
                        <th>Thumbnail</th>
                        <th>Author</th>
                        <th>Date Posted</th>
                        <th>Tags</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map(post => (
                        <tr key={post.id}>
                            <td>{post.title}</td>
                            <td>{post.subheading}</td>
                            <td><img src={post.thumbnail} alt="Thumbnail" width="50" /></td>
                            <td>{post.author}</td>
                            <td>{new Date(post.date_posted).toLocaleString()}</td>
                            <td>{post.tags.join(', ')}</td>
                            <td>{post.status === 'published' ? '✔️' : '❌'}</td>
                            <td>
                                <button className="edit-button" onClick={() => handleEdit(post)}>Edit</button>
                                <button className="delete-button" onClick={() => handleDelete(post.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BlogAdmin;