import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import './Login.css';

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.rfro-1000km.com/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        login(username, password);
        navigate('/');
      } else {
        toast.error(data.error || 'Invalid credentials');
      }
    } catch (error) {
      toast.error('An error occurred while logging in');
      console.error('Login error', error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.rfro-1000km.com/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.token) {
          login(username, password);  // Log in the user if a token is received
          navigate('/');  // Redirect to the homepage
          toast.success('Registration successful!');
        } else {
          toast.error(data.error || 'Registration failed: No token received.');
        }
      } else {
        toast.error(data.error || 'Registration failed.');
        console.error('Registration error:', data);
      }
    } catch (error) {
      toast.error('An error occurred while registering.');
      console.error('Registration error:', error);
    }
  };  

  return (
    <div className="login-container">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {isLogin ? (
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <input 
            type="text" 
            placeholder="Username" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="login-field"
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-field"
          />
          <button type="submit" className="primary-button">Login</button>
          <p>
            Don't have an account? 
          </p>
          <button type="button" onClick={() => setIsLogin(false)} className="secondary-button">Register</button>
        </form>
      ) : (
        <form className="register-form" onSubmit={handleRegister}>
          <h2>Register</h2>
          <input 
            type="text" 
            placeholder="Username" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="login-field"
          />
          <input 
            type="email" 
            placeholder="Email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-field"
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-field"
          />
          <button type="submit" className="primary-button">Register</button>
          <p>
            Already have an account? 
          </p>
          <button type="button" onClick={() => setIsLogin(true)} className="secondary-button">Login</button>
        </form>
      )}
    </div>
  );
};

export default Login;