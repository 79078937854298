import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { Typography, CircularProgress, Paper, Grid, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaTh, FaBars } from 'react-icons/fa';
import '../App.css';
import './Teams.css';

// Custom styles using the styled API
const TeamCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#444',
  color: '#fff',
  minHeight: '400px',
  position: 'relative',
  border: '2px solid #fc0',
  transition: 'transform 0.2s',
  overflow: 'hidden',
}));

const LogoContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: 'auto',
}));

const CarNumber = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  fontSize: '2rem',
  color: '#fff',
  fontWeight: 'bold',
  zIndex: 10,
}));

const TeamInfo = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(10),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TeamName = styled(Typography)(({ theme }) => ({
  color: '#fc0',
  fontWeight: 'bold',
}));

const CarMakeModel = styled(Typography)(({ theme }) => ({
  color: '#bbb',
}));

const DriverList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const CountryFlag = styled('img')(({ theme }) => ({
  width: '50px',
  height: 'auto',
  marginBottom: theme.spacing(1),
}));

const LiveryImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const ClassHeader = styled(Typography)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  color: color,
  textAlign: 'center',
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    flex: 1,
    height: '2px',
    backgroundColor: color,
    margin: theme.spacing(0, 2),
  },
}));

const classOrder = {
  Hypercar: 1,
  LMP2: 2,
  GT3: 3,
};

const sortItems = (items) => {
  return items.sort((a, b) => {
    const classComparison = classOrder[a.carclass] - classOrder[b.carclass];
    if (classComparison !== 0) return classComparison;
    return a.carnumber - b.carnumber;
  });
};

const Teams = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState('5');
  const [selectedClass, setSelectedClass] = useState('All');
  const [viewMode, setViewMode] = useState('grid');

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get('https://api.rfro-1000km.com/cars');
      setItems(sortItems(response.data));
    } catch (err) {
      setError('Failed to fetch data.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const renderTeamsGrid = () => {
    const classes = Array.from(new Set(items.map(item => item.carclass)));
    return (
      <>
        {classes.map(carClass => (
          <div key={carClass}>
            {(selectedClass === carClass || selectedClass === 'All') && (
              <ClassHeader variant="h4" color="#fc0">{carClass}</ClassHeader>
            )}
            <Grid container spacing={2}>
              {items
                .filter((team) => (selectedClass === carClass || selectedClass === 'All') && team.season === selectedSeason && team.carclass === carClass)
                .map((team) => (
                  <Grid item xs={12} sm={6} key={team.id}>
                    <Link to={`/teams/${team.id}`} style={{ textDecoration: 'none' }}>
                      <TeamCard>
                        <LogoContainer>
                          <StyledAvatar variant="square" src={team.teamlogo} alt={`${team.teamname} Logo`} />
                        </LogoContainer>
                        <CarNumber>#{team.carnumber}</CarNumber>
                        <TeamInfo>
                          <CountryFlag src={team.country} alt={`${team.teamname} Country Flag`} />
                          <TeamName variant="h6">{team.teamname}</TeamName>
                          <CarMakeModel variant="body2">{team.carmakemodel}</CarMakeModel>
                          <DriverList>
                            {team.drivers.map((driver, index) => (
                              <Typography key={index}>{driver}</Typography>
                            ))}
                          </DriverList>
                        </TeamInfo>
                        <LiveryImage src={team.livery} alt={`${team.teamname} Livery`} />
                      </TeamCard>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </div>
        ))}
      </>
    );
  };

  const renderTeamsList = () => (
    <div className="table-container" style={{ backgroundColor: 'black', color: 'white', padding: '16px', borderRadius: '8px' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', color: '#fff' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left' }}>Car Class</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Logo</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Team Name</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Country</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Car Number</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Car Make/Model</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Drivers</th>
          </tr>
        </thead>
        <tbody>
          {items
            .filter((team) => (selectedClass === 'All' || team.carclass === selectedClass) && team.season === selectedSeason)
            .map((team) => (
              <tr key={team.id} style={{ borderBottom: '1px solid #555' }}>
                <td style={{ padding: '8px', color: '#fff' }}>{team.carclass}</td>
                <td style={{ padding: '8px' }}>
                  <img src={team.teamlogo} alt={`${team.teamname} Logo`} style={{ width: '50px', height: 'auto' }} />
                </td>
                <td style={{ padding: '8px' }}>
                  <Link to={`/teams/${team.id}`} style={{ color: '#fc0', textDecoration: 'none' }}>
                    {team.teamname}
                  </Link>
                </td>
                <td style={{ padding: '8px' }}>
                  <img src={team.country} alt={`${team.teamname} Country Flag`} style={{ width: '50px', height: 'auto' }} />
                </td>
                <td style={{ padding: '8px' }}>#{team.carnumber}</td>
                <td style={{ padding: '8px' }}>{team.carmakemodel}</td>
                <td style={{ padding: '8px' }}>{team.drivers.join(', ')}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  
  );

  return (
    <div className="container">
      <Typography variant="h2" gutterBottom align="center">Teams</Typography>
      <Typography variant="body1" gutterBottom align="center">
        If you do not see your team here, or if any information is incorrect, please submit a <Link to="/support">support ticket</Link>.
      </Typography>
      <div className="selectors-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div className="season-selector-container" style={{ marginRight: '16px' }}>
          <label htmlFor="season-select">Season: </label>
          <select
            id="season-select"
            value={selectedSeason}
            onChange={handleSeasonChange}
            className="season-select"
          >
            <option value="5">Season 5</option>
          </select>
        </div>
        <div className="class-selector-container" style={{ marginRight: '16px' }}>
          <label htmlFor="class-select">Car Class: </label>
          <select
            id="class-select"
            value={selectedClass}
            onChange={handleClassChange}
            className="season-select"
          >
            <option value="All">All</option>
            <option value="Hypercar">Hypercar</option>
            <option value="LMP2">LMP2</option>
            <option value="GT3">GT3</option>
          </select>
        </div>
        <div className="view-selector-container">
          <button onClick={() => handleViewModeChange('grid')} style={{ backgroundColor: 'transparent', color: viewMode === 'grid' ? '#fc0' : 'white' }}>
            <FaTh size="32"/>
          </button>
          <button onClick={() => handleViewModeChange('list')} style={{ backgroundColor: 'transparent', color: viewMode === 'list' ? '#fc0' : 'white' }}>
            <FaBars size="32"/>
          </button>
        </div>
      </div>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : viewMode === 'grid' ? (
        renderTeamsGrid()
      ) : (
        renderTeamsList()
      )}
    </div>
  );
};

export default Teams;