import React from 'react';
import { Grid } from '@mui/material';
import CustomCard from './CustomCard';
import { CiSun } from "react-icons/ci";
import { FaCloudRain } from "react-icons/fa";

const cards = [
  {
    icon: <CiSun />,
    title: 'Dry',
    description: 'Join the dry practice server.',
    link: 'steam://run/365960//+connect 141.94.98.147:64278'
  },
  {
    icon: <FaCloudRain />,
    title: 'Wet',
    description: 'Join the wet practice server.',
    link: 'steam://run/365960//+connect 141.94.98.147:64279'
  },
  // Add more card objects as needed
];

const CardGrid = () => {
  return (
    <Grid container spacing={4} justifyContent="center">
      {cards.map((card, index) => (
        <Grid item key={index}>
          <CustomCard
            icon={card.icon}
            title={card.title}
            description={card.description}
            link={card.link}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardGrid;