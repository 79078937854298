// src/pages/Results.js
import React from 'react';
import '../App.css';

const Results = () => (
  <div className="container">
    <h1>Results and Standings</h1>
    <p>No data available yet. Results will be viewable after the <a href="/rounds/13">first race of Season 5.</a></p>
  </div>
);

export default Results;