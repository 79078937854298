import React from 'react';

const VideoPlayer = ({ selectedStream }) => {
  const { platform, channel } = selectedStream;

  let content;
  if (platform === 'twitch') {
    const src = `https://player.twitch.tv/?channel=${channel}&parent=rfro-1000km.com`;
    content = (
      <iframe
        src={src}
        frameBorder="0"
        allowFullScreen={true}
        scrolling="no"
        height="75%"
        width="100%"
        style={{ aspectRatio: '16/9' }} // This maintains the 16:9 aspect ratio directly in the iframe
        title={`Twitch stream for ${channel}`}
      ></iframe>
    );
  } else if (platform === 'youtube') {
    const url = `https://www.youtube.com/${channel}`;
    content = (
      <div>
        <p>Sorry, we are unable to display YouTube livestreams due to how youtube serves its content. You must view this livestream via the link to the channel below.</p>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {channel}
        </a>
      </div>
    );
  }

  return (
    <div>
      {content}
    </div>
  );
};

export default VideoPlayer;