import React from 'react';
import './Leaderboard.css';

const Leaderboard = () => {
  return (
    <div className="leaderboard">
      <h2 className="title">Leaderboard</h2>
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Class</th>
            <th>#</th>
            <th>Team</th>
            <th>Driver</th>
          </tr>
        </thead>
        <tbody>
          {/* Add rows here */}
        </tbody>
      </table>
      <h3 style={{ color: "red"}}>Live timing is currently unavailable. We are working on developing this feature.</h3>
    </div>
  );
};

export default Leaderboard;