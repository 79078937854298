import React from 'react';
import './Skins.css';

const liveryTemplates = [
  { name: 'Ferrari 499P', templateUrl: 'https://drive.google.com/drive/folders/1ehd5bzWHBSQjUHQrIcbNspj4pXmYtNc8?usp=sharing', class: 'Hypercar' },
  { name: 'Porsche 963 LMDh', templateUrl: 'https://drive.google.com/drive/folders/1W1lMSGB9-bAyhovHr9ZNVv6R68DZW4MN?usp=sharing', class: 'Hypercar' },
  { name: 'Oreca 07', templateUrl: 'https://drive.google.com/drive/folders/1yaU6LscnrQsp-fReWCMJZN20xF5920Kl?usp=sharing', class: 'LMP2' },
  { name: 'Mercedes AMG GT3', templateUrl: 'https://drive.google.com/drive/folders/1ujWjyKxHBbfYj-guM0Me7LblXKeWXM5U?usp=sharing', class: 'GT3' },
  { name: 'Ferrari 488 GT3 Evo', templateUrl: 'https://drive.google.com/drive/folders/1z9QE9ugCEbPigHamhVDfJFwofAqp08wF?usp=sharing', class: 'GT3' },
  { name: 'BMW M4 GT3', templateUrl: 'https://drive.google.com/drive/folders/1q3jFzbkYdQoPa_FW_hfp3uTL--jfpi34?usp=sharing', class: 'GT3' },
  { name: 'Porsche 911 GT3', templateUrl: 'https://drive.google.com/drive/folders/1rPy6c6eovifrTLs7vrnewPjQem5OJVC2?usp=sharing', class: 'GT3' },
  { name: 'Bentley Continental GT3', templateUrl: 'https://drive.google.com/drive/folders/19Trl5pGfLHBzmLy-ZyWiBIx7SNJDosnX?usp=sharing', class: 'GT3' },
  { name: 'McLaren 720S GT3', templateUrl: 'https://drive.google.com/drive/folders/1BR4xGNlN-1CotvtQCG8GSuzFGByx_fBw?usp=sharing', class: 'GT3' },
  { name: 'Aston Martin Vantage GT3', templateUrl: 'https://drive.google.com/drive/folders/1fA4yYmx_8MdOhDmbvxKwOccb8wHIQfWw?usp=sharing', class: 'GT3' },
  // Add more car templates as needed
];

const Skins = () => {
  return (
    <div className="skins-container">
      <h1>Livery Design Templates</h1>
      <div className="templates-list">
        {liveryTemplates.map((car, index) => (
          <a
            key={index}
            href={car.templateUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={`template-button ${car.class.toLowerCase()}`}
          >
            <button>
              {car.name} Template
            </button>
          </a>
        ))}
      </div>
      <a
        href="https://www.dropbox.com/request/aRxaiyLp9HnSSHv1hoyD"
        target="_blank"
        rel="noopener noreferrer"
        className="submission-button"
      >
        <button>
          Submit Your Livery
        </button>
      </a>
    </div>
  );
};

export default Skins;