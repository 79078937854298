import React from 'react';
import './InProgress.css'; // This is where you'll style the page

const InProgress = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h1 className="error-code">In Progress</h1>
        <p className="error-message">Oops! This page is currently a work in progress and is not available.</p>
      </div>
    </div>
  );
};

export default InProgress;