import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import {
  Typography, CircularProgress, Paper, Avatar
} from '@mui/material';

// Custom styles
const PageContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#222', // Dark background for the page
  color: '#fff', // White text
  minHeight: '100vh', // Full-height background
}));

const TeamCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#333', // Slightly lighter than the page background
  color: '#fff', // White text color
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  position: 'relative', // Allow absolute positioning for car number
}));

const LogoContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
}));

const CarNumber = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  color: '#fc0', // Yellow color for car number
  fontWeight: 'bold',
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const DriverList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  textAlign: 'center',
}));

const DriverName = styled(Typography)(({ theme }) => ({
  color: '#ddd', // Light grey color for driver names
  marginBottom: theme.spacing(1),
}));

const TeamInfo = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const CarMakeModel = styled(Typography)(({ theme }) => ({
  color: '#bbb', // Light grey color for car make/model text
  marginBottom: theme.spacing(2),
}));

const CountryFlag = styled('img')(({ theme }) => ({
  width: '50px',
  height: 'auto',
  marginBottom: theme.spacing(2),
}));

const LiveryImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const Team = () => {
  const { id } = useParams(); // Get the team ID from the URL
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await axios.get(`https://api.rfro-1000km.com/cars/${id}`);
        setTeam(response.data);
      } catch (err) {
        setError('Failed to fetch team data.');
        console.error('Error fetching team data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, [id]);

  if (loading) {
    return (
      <PageContainer>
        <CircularProgress color="inherit" />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </PageContainer>
    );
  }

  if (!team) {
    return (
      <PageContainer>
        <Typography variant="h6" color="error">
          Team not found.
        </Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <TeamCard>
        <LogoContainer>
          <StyledAvatar variant="square" src={team.teamlogo} alt={`${team.teamname} Logo`} />
        </LogoContainer>
        <CarNumber>#{team.carnumber}</CarNumber>
        <TeamInfo>
          <Typography variant="h4" style={{ color: '#fc0', fontWeight: 'bold' }}>
            {team.teamname}
          </Typography>
          <CountryFlag src={team.country} alt={`${team.teamname} Country Flag`} />
          <CarMakeModel variant="h6">{team.carmakemodel}</CarMakeModel>
        </TeamInfo>
        <DriverList>
          <Typography variant="h6" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
            Drivers
          </Typography>
          {team.drivers.map((driver, index) => (
            <DriverName key={index}>{driver}</DriverName>
          ))}
        </DriverList>
        <LiveryImage src={team.livery} alt={`${team.teamname} Livery`} />
      </TeamCard>
    </PageContainer>
  );
};

export default Team;