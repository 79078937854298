// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ element, requiredRole }) => {
    const { isAuthenticated, user } = useAuth();

    console.log('Auth Status:', isAuthenticated);
    console.log('User Data:', user);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (user && user.role_id !== requiredRole) {
        return <Navigate to="/unauthorised" />;
    }

    return element;
};

export default ProtectedRoute;