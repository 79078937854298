import React from 'react';
import { Box } from '@mui/material';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import CalendarSection from '../components/CalendarSection';
import NewsSection from '../components/NewsSection';
import PartnersSection from '../components/PartnersSection';

const Home = () => {
  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', minHeight: '100vh', overflowX: 'hidden' }}>
      <HeroSection />
      <AboutSection />
      <CalendarSection />
      <NewsSection />
      <PartnersSection />
    </Box>
  );
};

export default Home;
