import React, { useState, useEffect } from 'react';
import './ResultsAdmin.css';

const ResultsAdmin = () => {
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [allRounds, setAllRounds] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null);
  const [results, setResults] = useState([]);
  const [allCars, setAllCars] = useState([]);
  const [cars, setCars] = useState([]);
  const [formData, setFormData] = useState({ car_id: '', finishing_position: '', laps_completed: '' });
  const [editMode, setEditMode] = useState(false);

  const apiBaseUrl = 'https://api.rfro-1000km.com';

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/seasons`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setSeasons(data);
      } catch (error) {
        console.error("Failed to fetch seasons:", error);
      }
    };

    const fetchCars = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/cars`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setAllCars(data);
      } catch (error) {
        console.error("Failed to fetch cars:", error);
      }
    };

    fetchSeasons();
    fetchCars();
  }, []);

  useEffect(() => {
    const fetchRounds = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/rounds`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setAllRounds(data);
      } catch (error) {
        console.error("Failed to fetch rounds:", error);
      }
    };
    fetchRounds();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      const filteredRounds = allRounds.filter(round => round.season_id === selectedSeason.id);
      setRounds(filteredRounds);
    } else {
      setRounds([]);
    }
    setSelectedRound(null);
    setCars([]);
    setFormData({ car_id: '', finishing_position: '', laps_completed: '' });
    setEditMode(false);
  }, [selectedSeason, allRounds]);

  useEffect(() => {
    if (selectedSeason) {
      const filteredCars = allCars
        .filter(car => car.season === selectedSeason.season_number.toString())
        .sort((a, b) => a.carnumber - b.carnumber); // Sorting by carnumber in ascending order
      setCars(filteredCars);
    } else {
      setCars([]);
    }
  }, [selectedSeason, allCars]);

  useEffect(() => {
    if (selectedRound) {
      const fetchResults = async () => {
        try {
          const response = await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}`);
          if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
          setResults(data);
        } catch (error) {
          console.error("Failed to fetch results:", error);
        }
      };
      fetchResults();
    } else {
      setResults([]);
    }
  }, [selectedRound]);

  const handleSelectSeason = (event) => {
    const seasonId = event.target.value;
    const season = seasons.find((season) => season.id === parseInt(seasonId));
    setSelectedSeason(season);
  };

  const handleSelectRound = (event) => {
    const roundId = event.target.value;
    const round = rounds.find((round) => round.id === parseInt(roundId));
    setSelectedRound(round);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (editMode) {
        await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      } else {
        await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      }

      const response = await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setResults(data);

      setFormData({ car_id: '', finishing_position: '', laps_completed: '' });
      setEditMode(false);
    } catch (error) {
      console.error("Failed to submit result:", error);
    }
  };

  const handleEdit = (result) => {
    setFormData(result);
    setEditMode(true);
  };

  const handleDelete = async (resultId) => {
    try {
      await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}/${resultId}`, {
        method: 'DELETE',
      });

      const response = await fetch(`${apiBaseUrl}/results/rounds/${selectedRound.id}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Failed to delete result:", error);
    }
  };

  return (
    <div className="results-admin">
      <h1>Results Management</h1>

      <div className="form-group">
        <label htmlFor="season">Select Season:</label>
        <select id="season" onChange={handleSelectSeason}>
          <option value="">Select Season</option>
          {seasons
            .sort((a, b) => b.season_number - a.season_number) // Sort seasons by season_number descending
            .map((season) => (
              <option key={season.id} value={season.id}>
                Season {season.season_number}
              </option>
            ))}
        </select>
      </div>

      {selectedSeason && (
        <div className="form-group">
          <label htmlFor="round">Select Round:</label>
          <select id="round" onChange={handleSelectRound}>
            <option value="">Select Round</option>
            {rounds
              .sort((a, b) => a.round_number - b.round_number) // Sort rounds by round_number ascending
              .map((round) => (
                <option key={round.id} value={round.id}>
                  {round.name}
                </option>
              ))}
          </select>
        </div>
      )}

      {selectedRound && (
        <div className="form-group">
          <label htmlFor="car">Select Car:</label>
          <select
            id="car"
            name="car_id"
            value={formData.car_id}
            onChange={handleInputChange}
          >
            <option value="">Select Car</option>
            {cars.map((car) => (
              <option key={car.id} value={car.id}>
                #{car.carnumber} {car.teamname} {car.carclass}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedRound && (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="finishing_position">Finishing Position:</label>
              <input
                id="finishing_position"
                name="finishing_position"
                type="number"
                value={formData.finishing_position}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="laps_completed">Laps Completed:</label>
              <input
                id="laps_completed"
                name="laps_completed"
                type="number"
                value={formData.laps_completed}
                onChange={handleInputChange}
                required
              />
            </div>

            <button type="submit" className="results-submit">
              {editMode ? 'Update' : 'Add'} Result
            </button>
          </form>
        </div>
      )}

      {results.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Car</th>
              <th>Finishing Position</th>
              <th>Laps Completed</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.id}>
                <td>{result.car_id}</td>
                <td>{result.finishing_position}</td>
                <td>{result.laps_completed}</td>
                <td>
                  <button onClick={() => handleEdit(result)}>Edit</button>
                  <button onClick={() => handleDelete(result.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ResultsAdmin;