import React, { useState } from 'react';
import Leaderboard from '../components/Leaderboard';
import VideoPlayer from '../components/VideoPlayer';
import ChatHighlightsToggle from '../components/ChatHighlightsToggle';
import StreamSelector from '../components/StreamSelector';
import './Live.css';

const LivePage = () => {
  const [selectedStream, setSelectedStream] = useState({
    id: 'en',
    name: 'English',
    platform: 'twitch',
    channel: 'tunedtv_official',
  });

  return (
    <div className="live-page">
      <div className="sidebar">
        <Leaderboard />
      </div>
      <div className="main-content">
        <div className="video-player-section">
          {/* Pass the selectedStream to VideoPlayer */}
          <VideoPlayer selectedStream={selectedStream} className="video-player" />
          {/* Pass onStreamChange function to StreamSelector */}
          <StreamSelector onStreamChange={setSelectedStream} selectedStream={selectedStream} />
        </div>
        <div className="chat-highlights">
          {/* Pass the selectedStream to ChatHighlightsToggle */}
          <ChatHighlightsToggle selectedStream={selectedStream} />
        </div>
      </div>
    </div>
  );
};

export default LivePage;