import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { FaInstagram, FaYoutube, FaTwitch, FaFacebook, FaDiscord } from 'react-icons/fa'; // Importing icons from react-icons
import { FaXTwitter } from "react-icons/fa6";
import { CiGlobe } from "react-icons/ci";
import partner1Logo from '../assets/partner1.png'; // Example logo
import partner2Logo from '../assets/tunedtv.jpg'; // Example logo
import partner4Logo from '../assets/racestarttv.jpg';

const PartnersSection = () => {
  const partners = [
    {
      name: 'RFRO',
      logo: partner1Logo,
      link: 'https://racingfr.net',
      socialLinks: [
        { platform: 'Website', url: 'https://racingfr.net' },
        { platform: 'Facebook', url: 'https://www.facebook.com/RacingFROnline/' },
        { platform: 'Instagram', url: 'https://www.instagram.com/racingfr.online' },
        { platform: 'X', url: 'https://x.com/RacingfrOnline' },
        { platform: 'YouTube', url: 'https://www.youtube.com/channel/UCObJgUN1Lt4CzECRkTpnX8g' },
        { platform: 'Discord', url: 'https://discord.com/invite/012hi1JRWHR5SJxw8' },
      ],
    },
    {
      name: 'TunedTV',
      logo: partner2Logo,
      link: 'https://twitch.tv/tunedtv_official',
      socialLinks: [
        { platform: 'Twitch', url: 'https://twitch.tv/tunedtv_official' },
        { platform: 'YouTube', url: 'https://www.youtube.com/@tunedtv1877' },
      ],
    },
    {
      name: 'Racestart TV',
      logo: partner4Logo,
      link: 'https://twitch.tv/racestarttv',
      socialLinks: [
        { platform: 'Discord', url: 'https://discord.com/invite/jf9Y7Cq' },
        { platform: 'Facebook', url: 'https://www.facebook.com/RaceStart.tv' },
        { platform: 'Instagram', url: 'https://instagram.com/racestart.tv' },
        { platform: 'Twitch', url: 'https://www.twitch.tv/racestarttv' },
        { platform: 'YouTube', url: 'https://youtube.com/racestarttv' },
      ],
    },
  ];

  const getIcon = (platform) => {
    switch (platform) {
      case 'Instagram':
        return <FaInstagram />;
      case 'YouTube':
        return <FaYoutube />;
      case 'Twitch':
        return <FaTwitch />;
      case 'Facebook':
        return <FaFacebook />;
      case 'Discord':
        return <FaDiscord />;
      case 'X':
        return <FaXTwitter />;
      case 'Website':
        return <CiGlobe />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Partners
      </Typography>
      <Box display="flex" justifyContent="space-around">
        {partners.map((partner, index) => (
          <Box key={index} textAlign="center">
            <a href={partner.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={partner.logo} alt={partner.name} style={{ width: '100px' }} />
              <Typography variant="body1">{partner.name}</Typography>
            </a>
            <Box display="flex" justifyContent="center" marginTop={1}>
              {partner.socialLinks.map((social, idx) => (
                <IconButton
                  key={idx}
                  component="a"
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={social.platform}
                  color="inherit"
                >
                  {getIcon(social.platform) || (
                    <Typography variant="body2" style={{ padding: '5px' }}>
                      {social.platform}
                    </Typography>
                  )}
                </IconButton>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default PartnersSection;