import React from 'react';
import './Unauthorised.css'; // This is where you'll style the page

const Unauthorised = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h1 className="error-code">Unauthorised</h1>
        <p className="error-message">Oops! You don't have permission to view this page. Submit a <a href="/support" class="support-link">support ticket</a> if you think this is a bug.</p>
      </div>
    </div>
  );
};

export default Unauthorised;