import React from 'react';
import '../App.css';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <p>&copy; 2024 KCVI</p>
    <div className="footer-links">
      <p>
        <a href="https://discord.gg/3QAeEctZe3" target="_blank" rel="noopener noreferrer">
          Join our Discord
        </a>
      </p>
      <p>
        <a href="/assets/privacy-notice.pdf" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </p>
      <p>
        <a href="https://stats.uptimerobot.com/LiVFbFucCb" target="_blank" rel="noopener noreferrer">
          Status
        </a>
      </p>
    </div>
    <p className="version-label">Version: 1.0.1</p>
  </footer>
);

export default Footer;