import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import heroImage from '../assets/porsche_ferrari_spa.jpg';

const HeroSection = () => {
  return (
    <Box
      textAlign="center"
      mb={4}
      className="hero-section"
      sx={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '8rem 0',
        borderRadius: '8px',
        position: 'relative',
        color: '#fff',
        width: '100vw',
        marginLeft: 'calc(50% - 50vw)',
        marginRight: 'calc(50% - 50vw)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
          zIndex: 1,
        }}
      ></Box>
      <Container disableGutters>
        <Box position="relative" zIndex={2}>
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to the RFRO 1000KM Series
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;