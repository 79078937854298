import React, { useState } from 'react';
import { FaLock } from "react-icons/fa";
import './ChatHighlightsToggle.css';

const ChatHighlightsToggle = ({ selectedStream }) => {
  const [view, setView] = useState('chat'); // Either 'chat' or 'highlights'

  const renderChatOrHighlights = () => {
    if (view === 'chat') {
      if (selectedStream.platform === 'twitch') {
        return (
          <iframe
            src={`https://www.twitch.tv/embed/${selectedStream.channel}/chat?parent=rfro-1000km.com`}
            height="75%"
            width="100%"
            frameBorder="0"
            scrolling="no"
            title={`${selectedStream.name} Chat`}
          />
        );
      } else if (selectedStream.platform === 'youtube') {
        return <p>Chat is not available for YouTube streams.</p>;
      }
    } else {
      return <h3 className="highlights-text">Highlights are currently unavailable. We are working on developing this feature. It will be for premium subscribers only.</h3>;
    }
    return null;
  };

  return (
    <div className="cht-toggle-container">
      <div className="cht-toggle-buttons">
        <button
          className={`cht-toggle-button ${view === 'chat' ? 'cht-active' : ''}`}
          onClick={() => setView('chat')}
        >
          Chat
        </button>
        <button
          className={`cht-toggle-button ${view === 'highlights' ? 'cht-active' : ''}`}
          onClick={() => setView('highlights')}
        >
          <FaLock color="red"/>
          Highlights
        </button>
      </div>
      <div className="cht-toggle-content">{renderChatOrHighlights()}</div>
    </div>
  );
};

export default ChatHighlightsToggle;