import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Card, CardContent, Chip } from '@mui/material';
import { Link } from 'react-router-dom';

const NewsSection = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch('https://api.rfro-1000km.com/blog');
      const data = await response.json();
      // Filter posts to include only published ones
      const publishedPosts = data.filter(post => post.status === 'published');
      // Limit to the first 5 published posts
      setPosts(publishedPosts.slice(0, 5));
    };

    fetchPosts();
  }, []);

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        News
      </Typography>
      {posts.length === 0 ? (
        <Typography variant="h6" align="center" color="white"
          sx={{
            marginBottom: '5vh'
          }}>
          No published posts available.
        </Typography>
      ) : (
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2} justifyContent="center">
          {posts.map((post) => (
            <Link
              key={post.id}
              to={`/blog/${post.id}`}
              style={{ textDecoration: 'none' }} // Remove default link underline
            >
              <Card
                sx={{
                  width: 300,
                  position: 'relative',
                  backgroundColor: 'black',
                  border: '2px solid #fc0',
                  borderRadius: '32px',
                  color: 'white',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  marginBottom: '5vh', // Show a pointer cursor to indicate the card is clickable
                }}
              >
                <CardContent
                  sx={{
                    padding: 2, // Adjust padding for spacing inside the card
                  }}
                >
                  <Typography variant="h5" sx={{ color: '#fc0' }}>
                    {post.title}
                  </Typography>
                  <Typography variant="h6" sx={{ color: 'white' }}>
                    {post.subheading}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'white', fontStyle: 'italic' }}>
                    {post.date_posted.split('T')[0]}
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                    {post.tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        sx={{
                          backgroundColor: '#666',
                          color: 'white',
                          borderRadius: '16px',
                          border: '1px solid #666'
                        }}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Link>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default NewsSection;