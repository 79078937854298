import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import './Profile.css';

const Profile = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (user) {
      fetchUserInfo();
    }
  }, [user]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get('https://api.rfro-1000km.com/me', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  if (!userInfo) {
    return (
      <div className="profile-container">
        <h1>Profile Page</h1>
        <p>Loading user data...</p>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h1>Profile</h1>
      <Alert variant="filled" severity="warning">
        This page is currently a work in progress, but we have provided some of the functionality here.
      </Alert>
      <div className="profile-section">
        <div className="profile-info">
          <p><strong>Name:</strong> {userInfo.username}</p>
          <p><strong>Email:</strong> {userInfo.email}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;