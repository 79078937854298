import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Card, CardContent, IconButton } from '@mui/material';
import { FaChevronCircleRight } from 'react-icons/fa'; // Importing FaChevronCircleRight
import { styled } from '@mui/system';

const fetchRounds = async () => {
  const response = await fetch('https://api.rfro-1000km.com/rounds');
  const data = await response.json();
  return data;
};

// Styled component for greyed-out card
const GreyedOutCard = styled(Card)({
  backgroundColor: '#666',
  color: '#fff',
});

const CalendarSection = () => {
  const [rounds, setRounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousRound, setPreviousRound] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [nextRound, setNextRound] = useState(null);

  useEffect(() => {
    const getRounds = async () => {
      const roundsData = await fetchRounds();
      setRounds(roundsData); // Store the fetched data in state
      const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
      const sortedRounds = roundsData.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Find past, current, and upcoming rounds
      const pastRounds = sortedRounds.filter(round => round.date < today);
      const upcomingRounds = sortedRounds.filter(round => round.date >= today);

      setPreviousRound(pastRounds[pastRounds.length - 1] || null);
      setCurrentRound(upcomingRounds[0] || null);
      setNextRound(upcomingRounds[1] || null);
      setLoading(false);
    };

    getRounds();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
        <Typography variant="h4" component="h2" gutterBottom>Calendar</Typography>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box textAlign="center" mb={4} sx={{ padding: '2rem', color: '#fff' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Calendar
      </Typography>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {/* Previous Round Card */}
        {rounds.length > 0 && previousRound && (
          <GreyedOutCard sx={{ maxWidth: 345, margin: '1rem', opacity: '0.5'}}>
            <CardContent>
              <img src={previousRound.image_url} alt={previousRound.name} style={{ width: '100%', borderRadius: '8px' }} />
              <Typography variant="h5" component="h2" mt={2}>
                {previousRound.name}
              </Typography>
              <Typography variant="body2" component="p">
                {new Date(previousRound.date).toLocaleDateString()}
              </Typography>
              <IconButton href={`/rounds/${previousRound.id}`} sx={{ color: '#fff', mt: 2 }}>
                <FaChevronCircleRight />
              </IconButton>
            </CardContent>
          </GreyedOutCard>
        )}
        {/* Current Round Card */}
        {rounds.length > 0 && currentRound && (
          <Card sx={{ maxWidth: 345, margin: '1rem' }}>
            <CardContent sx={{ backgroundColor: '#fc0'}}>
              <img src={currentRound.image_url} alt={currentRound.name} style={{ width: '100%', borderRadius: '8px' }} />
              <Typography variant="h5" component="h2" mt={2}>
                {currentRound.name}
              </Typography>
              <Typography variant="body2" component="p">
                {new Date(currentRound.date).toLocaleDateString()}
              </Typography>
              <IconButton href={`/rounds/${currentRound.id}`} sx={{ color: '#000', mt: 2 }}>
                <FaChevronCircleRight />
              </IconButton>
            </CardContent>
          </Card>
        )}
        {/* Next Round Card */}
        {rounds.length > 0 && nextRound && (
          <GreyedOutCard sx={{ maxWidth: 345, margin: '1rem' }}>
            <CardContent>
              <img src={nextRound.image_url} alt={nextRound.name} style={{ width: '100%', borderRadius: '8px' }} />
              <Typography variant="h5" component="h2" mt={2}>
                {nextRound.name}
              </Typography>
              <Typography variant="body2" component="p">
                {new Date(nextRound.date).toLocaleDateString()}
              </Typography>
              <IconButton href={`/rounds/${nextRound.id}`} sx={{ color: '#fff', mt: 2 }}>
                <FaChevronCircleRight />
              </IconButton>
            </CardContent>
          </GreyedOutCard>
        )}
      </Box>
    </Box>
  );
};

export default CalendarSection;