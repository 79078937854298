import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import aboutImage from '../assets/bmw_ferrari_le_mans.jpg'; // Import the image directly

const AboutSection = () => {
  return (
    <Box 
      textAlign="center" 
      mb={4}
      sx={{
        backgroundImage: `url(${aboutImage})`, // Use the imported image directly
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '4rem 2rem',
        borderRadius: '8px',
        position: 'relative',
        color: '#fff',
        width: '100vw',
        marginLeft: 'calc(50% - 50vw)',
        marginRight: 'calc(50% - 50vw)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
          zIndex: 1,
        }}
      ></Box>
      <Container disableGutters>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'column', md: 'row' }}
          sx={{ height: '100%' }}
        >
          <Box
            sx={{ width: { xs: '100%', md: '50%' }, padding: '2rem', textAlign: 'left', position: 'relative', zIndex: 2 }}
          >
            <Typography variant="body1" component="p" sx={{ color: '#fff' }}>
              RFRO is an endurance sim racing league where racing enthusiasts from around the world
              come together to compete in rFactor 2. Our league offers competitive
              racing, community engagement, and a platform for drivers of all skill levels to
              showcase their talents. Whether you're a seasoned pro or new to sim racing, RFRO
              provides a thrilling and inclusive racing experience.
            </Typography>
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', justifyContent: 'flex-end', padding: '2rem', position: 'relative', zIndex: 2 }}
          >
            <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#fff' }}>
              About RFRO
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutSection;