import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ScheduleAdmin.css';

const ScheduleAdmin = () => {
  // State for seasons
  const [seasons, setSeasons] = useState([]);
  const [newSeason, setNewSeason] = useState({ season_number: '' });
  const [editSeason, setEditSeason] = useState(null);

  // State for rounds
  const [rounds, setRounds] = useState([]);
  const [newRound, setNewRound] = useState({
    round_number: '',
    name: '',
    location: '',
    number_of_laps: '',
    date: '',
    season_id: '',
    qualifying_results_link: '',
    race_results_link: '',
    vod_links: { French: '', English: '', Portugese: '' },
    image_url: ''
  });
  const [editRound, setEditRound] = useState(null);

  // Modal state for confirmation and editing
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeletingRound, setIsDeletingRound] = useState(false);
  const [isDeletingSeason, setIsDeletingSeason] = useState(false);

  // Fetch seasons and rounds
  const fetchSeasons = useCallback(async () => {
    try {
      const response = await axios.get('https://api.rfro-1000km.com/seasons');
      setSeasons(response.data);
    } catch (error) {
      console.error('Error fetching seasons:', error);
    }
  }, []);

  const fetchRounds = useCallback(async () => {
    try {
      const response = await axios.get('https://api.rfro-1000km.com/rounds');
      const sortedRounds = response.data.sort((a, b) => {
        const seasonA = seasons.find(season => season.id === a.season_id)?.season_number || 0;
        const seasonB = seasons.find(season => season.id === b.season_id)?.season_number || 0;
        return seasonA !== seasonB ? seasonA - seasonB : a.round_number - b.round_number;
      });
      setRounds(sortedRounds);
    } catch (error) {
      console.error('Error fetching rounds:', error);
    }
  }, [seasons]);

  useEffect(() => {
    fetchSeasons();
  }, [fetchSeasons]);

  useEffect(() => {
    if (seasons.length > 0) {
      fetchRounds();
    }
  }, [fetchRounds, seasons]);

  // Handlers for input changes
  const handleInputChange = (e, stateSetter, stateValue) => {
    const { name, value } = e.target;
    stateSetter(prev => ({ ...prev, [name]: value }));
  };

  const handleVodLinkChange = (e) => {
    const { name, value } = e.target;
    
    if (editRound) {
      setEditRound(prevRound => ({
        ...prevRound,
        vod_links: { ...prevRound.vod_links, [name]: value }
      }));
    } else {
      setNewRound(prevRound => ({
        ...prevRound,
        vod_links: { ...prevRound.vod_links, [name]: value }
      }));
    }
  };

  // CRUD operations
  const handleCreateSeason = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.rfro-1000km.com/seasons', newSeason);
      fetchSeasons();
      setNewSeason({ season_number: '' });
    } catch (error) {
      console.error('Error creating season:', error);
    }
  };

  const handleUpdateSeason = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.rfro-1000km.com/seasons/${editSeason.id}`, editSeason);
      fetchSeasons();
      setEditSeason(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error updating season:', error);
    }
  };

  const handleDeleteSeason = async (id) => {
    setIsDeletingSeason(true);
    try {
      await axios.delete(`https://api.rfro-1000km.com/seasons/${id}`);
      fetchSeasons();
      setShowModal(false);
    } catch (error) {
      console.error('Error deleting season:', error);
    } finally {
      setIsDeletingSeason(false);
    }
  };

  const handleCreateRound = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.rfro-1000km.com/rounds', newRound);
      fetchRounds();
      setNewRound({
        round_number: '',
        name: '',
        location: '',
        number_of_laps: '',
        date: '',
        season_id: '',
        qualifying_results_link: '',
        race_results_link: '',
        vod_links: { French: '', English: '', Portugese: '' },
        image_url: ''
      });
    } catch (error) {
      console.error('Error creating round:', error);
    }
  };

  const handleUpdateRound = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.rfro-1000km.com/rounds/${editRound.id}`, editRound);
      fetchRounds();
      setEditRound(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error updating round:', error);
    }
  };

  const handleDeleteRound = async (id) => {
    setIsDeletingRound(true);
    try {
      await axios.delete(`https://api.rfro-1000km.com/rounds/${id}`);
      fetchRounds();
      setShowModal(false);
    } catch (error) {
      console.error('Error deleting round:', error);
    } finally {
      setIsDeletingRound(false);
    }
  };

  const confirmDelete = (id, type) => {
    setDeleteItemId({ id, type });
    setModalType('confirmation');
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    if (deleteItemId?.type === 'round') {
      handleDeleteRound(deleteItemId.id);
    } else if (deleteItemId?.type === 'season') {
      handleDeleteSeason(deleteItemId.id);
    }
  };

  const openEditModal = (item, type) => {
    if (type === 'season') {
      setEditSeason(item);
      setModalType('edit-season');
    } else if (type === 'round') {
      setEditRound(item);
      setModalType('edit-round');
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditSeason(null);
    setEditRound(null);
  };

  return (
    <div className="schedule-admin">
      <h1 className="page-header">Schedule Management</h1>

      {/* Seasons Management */}
      <section className="admin-section">
        <h2>Manage Seasons</h2>
        <form className="create-form" onSubmit={editSeason ? handleUpdateSeason : handleCreateSeason}>
          <input
            type="text"
            name="season_number"
            value={editSeason ? editSeason.season_number : newSeason.season_number}
            onChange={(e) => handleInputChange(e, editSeason ? setEditSeason : setNewSeason, editSeason ? editSeason.season_number : newSeason.season_number)}
            placeholder="Season Number"
            required
          />
          <button type="submit">{editSeason ? 'Update Season' : 'Create Season'}</button>
          {!editSeason && <button type="reset" onClick={() => setNewSeason({ season_number: '' })}>Cancel</button>}
        </form>

        <table>
          <thead>
            <tr>
              <th>Season Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {seasons.map(season => (
              <tr key={season.id}>
                <td>{season.season_number}</td>
                <td>
                  <button className="edit-button" onClick={() => openEditModal(season, 'season')}>Edit</button>
                  <button className="delete-button" onClick={() => confirmDelete(season.id, 'season')}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Rounds Management */}
      <section className="admin-section">
        <h2>Manage Rounds</h2>
        <form className="create-form" onSubmit={editRound ? handleUpdateRound : handleCreateRound}>
          <input
            type="number"
            name="round_number"
            value={editRound ? editRound.round_number : newRound.round_number}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.round_number : newRound.round_number)}
            placeholder="Round Number"
            required
          />
          <input
            type="text"
            name="name"
            value={editRound ? editRound.name : newRound.name}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.name : newRound.name)}
            placeholder="Round Name"
            required
          />
          <input
            type="text"
            name="location"
            value={editRound ? editRound.location : newRound.location}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.location : newRound.location)}
            placeholder="Location"
            required
          />
          <input
            type="number"
            name="number_of_laps"
            value={editRound ? editRound.number_of_laps : newRound.number_of_laps}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.number_of_laps : newRound.number_of_laps)}
            placeholder="Number of Laps"
            required
          />
          <input
            type="date"
            name="date"
            value={editRound ? editRound.date : newRound.date}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.date : newRound.date)}
            required
          />
          <select
            name="season_id"
            value={editRound ? editRound.season_id : newRound.season_id}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.season_id : newRound.season_id)}
            required
          >
            <option value="">Select Season</option>
            {seasons.map(season => (
              <option key={season.id} value={season.id}>{season.season_number}</option>
            ))}
          </select>
          <input
            type="text"
            name="qualifying_results_link"
            value={editRound ? editRound.qualifying_results_link : newRound.qualifying_results_link}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.qualifying_results_link : newRound.qualifying_results_link)}
            placeholder="Qualifying Results Link"
          />
          <input
            type="text"
            name="race_results_link"
            value={editRound ? editRound.race_results_link : newRound.race_results_link}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.race_results_link : newRound.race_results_link)}
            placeholder="Race Results Link"
          />
          <input
            type="text"
            name="French"
            value={editRound ? editRound.vod_links.French : newRound.vod_links.French}
            onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
            placeholder="VOD Link (French)"
          />
          <input
            type="text"
            name="English"
            value={editRound ? editRound.vod_links.English : newRound.vod_links.English}
            onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
            placeholder="VOD Link (English)"
          />
          <input
            type="text"
            name="Portugese"
            value={editRound ? editRound.vod_links.Portugese : newRound.vod_links.Portugese}
            onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
            placeholder="VOD Link (Portugese)"
          />
          <input
            type="text"
            name="image_url"
            value={editRound ? editRound.image_url : newRound.image_url}
            onChange={(e) => handleInputChange(e, editRound ? setEditRound : setNewRound, editRound ? editRound.image_url : newRound.image_url)}
            placeholder="Image URL"
          />
          <button type="submit">{editRound ? 'Update Round' : 'Create Round'}</button>
          {editRound && <button type="button" onClick={() => setEditRound(null)}>Cancel</button>}
        </form>

        <table>
          <thead>
            <tr>
              <th>Season</th>
              <th>Round Number</th>
              <th>Image</th>
              <th>Name</th>
              <th>Location</th>
              <th>Number of Laps</th>
              <th>Date</th>
              <th>Qualifying Link</th>
              <th>Race Link</th>
              <th>VOD Links</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rounds.map(round => (
              <tr key={round.id}>
                <td>{seasons.find(season => season.id === round.season_id)?.season_number}</td>
                <td>{round.round_number}</td>
                <td><img src={round.image_url} alt={round.name} /></td>
                <td>{round.name}</td>
                <td>{round.location}</td>
                <td>{round.number_of_laps}</td>
                <td>{round.date}</td>
                <td><a href={round.qualifying_results_link} target="_blank" rel="noopener noreferrer">Link</a></td>
                <td><a href={round.race_results_link} target="_blank" rel="noopener noreferrer">Link</a></td>
                <td>
                  <a href={round.vod_links.French} target="_blank" rel="noopener noreferrer">French</a><br />
                  <a href={round.vod_links.English} target="_blank" rel="noopener noreferrer">English</a><br />
                  <a href={round.vod_links.Portugese} target="_blank" rel="noopener noreferrer">Portugese</a>
                </td>
                <td>
                  <button className="edit-button" onClick={() => openEditModal(round, 'round')}>Edit</button>
                  <button className="delete-button" onClick={() => confirmDelete(round.id, 'round')}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Modal for editing */}
      {showModal && modalType === 'edit-season' && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit Season</h3>
            <form onSubmit={handleUpdateSeason}>
              <input
                type="text"
                name="season_number"
                value={editSeason?.season_number || ''}
                onChange={(e) => handleInputChange(e, setEditSeason, editSeason?.season_number)}
                placeholder="Season Number"
                required
              />
              <button type="submit">Update Season</button>
              <button type="button" onClick={closeModal}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {showModal && modalType === 'edit-round' && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit Round</h3>
            <form onSubmit={handleUpdateRound}>
              <label>Round Number</label>
              <input
                type="number"
                name="round_number"
                value={editRound?.round_number || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.round_number)}
                placeholder="Round Number"
                required
              />
              <label>Round Name</label>
              <input
                type="text"
                name="name"
                value={editRound?.name || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.name)}
                placeholder="Round Name"
                required
              />
              <label>Location</label>
              <input
                type="text"
                name="location"
                value={editRound?.location || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.location)}
                placeholder="Location"
                required
              />
              <label>No. of laps</label>
              <input
                type="number"
                name="number_of_laps"
                value={editRound?.number_of_laps || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.number_of_laps)}
                placeholder="Number of Laps"
                required
              />
              <label>Date</label>
              <input
                type="date"
                name="date"
                value={editRound?.date || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.date)}
                required
              />
              <label>Season No.</label>
              <select
                name="season_id"
                value={editRound?.season_id || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.season_id)}
                required
              >
                <option value="">Select Season</option>
                {seasons.map(season => (
                  <option key={season.id} value={season.id}>{season.season_number}</option>
                ))}
              </select>
              <label>Quali Results Link</label>
              <input
                type="text"
                name="qualifying_results_link"
                value={editRound?.qualifying_results_link || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.qualifying_results_link)}
                placeholder="Qualifying Results Link"
              />
              <label>Race Results Link</label>
              <input
                type="text"
                name="race_results_link"
                value={editRound?.race_results_link || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.race_results_link)}
                placeholder="Race Results Link"
              />
              <label>French VOD Link</label>
              <input
                type="text"
                name="French"
                value={editRound ? editRound.vod_links.French : newRound.vod_links.French}
                onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
                placeholder="VOD Link (French)"
              />
              <label>English VOD Link</label>
              <input
                type="text"
                name="English"
                value={editRound ? editRound.vod_links.English : newRound.vod_links.English}
                onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
                placeholder="VOD Link (English)"
              />
              <label>Portugese VOD Link</label>
              <input
                type="text"
                name="Portugese"
                value={editRound ? editRound.vod_links.Portugese : newRound.vod_links.Portugese}
                onChange={(e) => handleVodLinkChange(e, editRound ? setEditRound : setNewRound)}
                placeholder="VOD Link (Portugese)"
              />
              <label>Image URL</label>
              <input
                type="text"
                name="image_url"
                value={editRound?.image_url || ''}
                onChange={(e) => handleInputChange(e, setEditRound, editRound?.image_url)}
                placeholder="Image URL"
              />
              <button type="submit">Update Round</button>
              <button type="button" onClick={closeModal}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {/* Modal for confirmation */}
      {showModal && modalType === 'confirmation' && (
        <div className="modal">
          <div className="modal-content">
            <h3>Are you sure?</h3>
            <p>Do you really want to delete this {deleteItemId?.type}?</p>
            <button onClick={handleModalConfirm} disabled={isDeletingSeason || isDeletingRound}>Yes, Delete</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleAdmin;