import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './EntriesAdmin.css'; // Import the CSS file

const EntriesAdmin = () => {
    const [items, setItems] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        carclass: '',
        classlogo: '',
        teamlogo: '',
        carnumber: '',
        carmakemodel: '',
        drivers: '',
        teamname: '',
        season: '', 
        country: '',
        livery: ''
    });
    const [editFormData, setEditFormData] = useState({
        id: null,
        carclass: '',
        classlogo: '',
        teamlogo: '',
        carnumber: '',
        carmakemodel: '',
        drivers: '',
        teamname: '',
        season: '', 
        country: '',
        livery: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    // Options for Car Make/Model based on selected Car Class
    const carMakeModelOptions = {
        Hypercar: ['Ferrari 499P', 'Porsche 963 LMDh'],
        LMP2: ['Oreca 07'],
        GT3: [
            'Mercedes AMG GT3',
            'Ferrari 488 GT3 Evo',
            'BMW M4 GT3',
            'Porsche 911 GT3',
            'Bentley Continental GT3',
            'McLaren 720S GT3',
            'Aston Martin Vantage GT3'
        ]
    };

    // Options for countries and their corresponding flag image URLs
    const countryOptions = {
        France: 'https://flagcdn.com/w320/fr.png',
        Portugal: 'https://flagcdn.com/w320/pt.png',
        Italy: 'https://flagcdn.com/w320/it.png',
        UK: 'https://flagcdn.com/w320/gb.png',
        Brazil: 'https://flagcdn.com/w320/br.png',
        Argentina: 'https://flagcdn.com/w320/ar.png',
        Germany: 'https://flagcdn.com/w320/de.png',
        Hungary: 'https://flagcdn.com/w320/hu.png',
        Spain: 'https://flagcdn.com/w320/es.png'
    };

    const fetchItems = useCallback(async () => {
        try {
            const response = await axios.get('https://api.rfro-1000km.com/cars');
            setItems(sortItems(response.data)); // Apply sorting before setting state
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const sortItems = (items) => {
        const classOrder = {
            Hypercar: 1,
            LMP2: 2,
            GT3: 3,
        };

        return items.sort((a, b) => {
            // Compare car classes
            const classComparison = classOrder[a.carclass] - classOrder[b.carclass];
            if (classComparison !== 0) return classComparison;

            // Compare car numbers
            return a.carnumber - b.carnumber;
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({
            ...editFormData,
            [name]: value
        });
    };

    const handleClassChange = (e) => {
        const carclass = e.target.value;
        const classLogoMap = {
            Hypercar: 'https://i.imgur.com/4hwM9Is.png',
            LMP2: 'https://i.imgur.com/PZYOBe6.png',
            GT3: 'https://i.imgur.com/kqYFwkr.png'
        };
        setFormData({
            ...formData,
            carclass,
            classlogo: classLogoMap[carclass] || '',
            carmakemodel: '' // Reset Car Make/Model when Car Class changes
        });
    };

    const handleEditClassChange = (e) => {
        const carclass = e.target.value;
        const classLogoMap = {
            Hypercar: 'https://i.imgur.com/4hwM9Is.png',
            LMP2: 'https://i.imgur.com/PZYOBe6.png',
            GT3: 'https://i.imgur.com/kqYFwkr.png'
        };
        setEditFormData({
            ...editFormData,
            carclass,
            classlogo: classLogoMap[carclass] || '',
            carmakemodel: '' // Reset Car Make/Model when Car Class changes
        });
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        setFormData({
            ...formData,
            country // Store selected country name
        });
    };

    const handleEditCountryChange = (e) => {
        const country = e.target.value;
        setEditFormData({
            ...editFormData,
            country // Store selected country name
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const driversArray = formData.drivers.split(',').map(driver => driver.trim());
    
        const { id, ...dataToSend } = formData;
        const formattedData = {
            ...dataToSend,
            drivers: driversArray,
            country: countryOptions[formData.country] // Convert country name to flag URL before submitting
        };
    
        console.log('Formatted Data:', formattedData);
    
        try {
            if (id) {
                await axios.put(`https://api.rfro-1000km.com/cars/${id}`, formattedData);
            } else {
                await axios.post('https://api.rfro-1000km.com/cars', formattedData);
            }
            fetchItems();
            resetForm();
        } catch (error) {
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        
        const driversArray = editFormData.drivers.split(',').map(driver => driver.trim());
    
        const { id, ...dataToSend } = editFormData;
        const formattedData = {
            ...dataToSend,
            drivers: driversArray,
            country: countryOptions[editFormData.country] // Convert country name to flag URL before submitting
        };
    
        console.log('Formatted Data:', formattedData);
    
        try {
            await axios.put(`https://api.rfro-1000km.com/cars/${id}`, formattedData);
            fetchItems();
            setShowEditModal(false);
            resetForm();
        } catch (error) {
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleEdit = (item) => {
        const countryName = Object.keys(countryOptions).find(key => countryOptions[key] === item.country);

        setEditFormData({
            id: item.id,
            carclass: item.carclass,
            classlogo: item.classlogo,
            teamlogo: item.teamlogo,
            carnumber: item.carnumber,
            carmakemodel: item.carmakemodel,
            drivers: item.drivers.join(', '),
            teamname: item.teamname,
            season: item.season, 
            country: countryName || '', // Convert flag URL back to country name
            livery: item.livery
        });
        setShowEditModal(true);
    };

    const handleDelete = async (id) => {
        await axios.delete(`https://api.rfro-1000km.com/cars/${id}`);
        fetchItems();
        setShowModal(false);
    };

    const resetForm = () => {
        setFormData({
            id: null,
            carclass: '',
            classlogo: '',
            teamlogo: '',
            carnumber: '',
            carmakemodel: '',
            drivers: '',
            teamname: '',
            season: '', 
            country: '',
            livery: ''
        });
    };

    const confirmDelete = (id) => {
        setDeleteItemId(id);
        setShowModal(true);
    };

    const getCarMakeModelOptions = () => {
        return carMakeModelOptions[formData.carclass] || [];
    };

    const getEditCarMakeModelOptions = () => {
        return carMakeModelOptions[editFormData.carclass] || [];
    };

    return (
        <div>
            <h1>Entries Admin</h1>
            <form className="create-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="teamname"
                    placeholder="Team Name"
                    value={formData.teamname}
                    onChange={handleInputChange}
                />
                <select
                    name="carclass"
                    value={formData.carclass}
                    onChange={handleClassChange}
                >
                    <option value="">Select Class</option>
                    <option value="Hypercar">Hypercar</option>
                    <option value="LMP2">LMP2</option>
                    <option value="GT3">GT3</option>
                </select>
                <select
                    name="carmakemodel"
                    value={formData.carmakemodel}
                    onChange={handleInputChange}
                    disabled={!formData.carclass}
                >
                    <option value="">Select Car Make/Model</option>
                    {getCarMakeModelOptions().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    name="carnumber"
                    placeholder="Car Number"
                    value={formData.carnumber}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="drivers"
                    placeholder="Drivers (comma-separated)"
                    value={formData.drivers}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="teamlogo"
                    placeholder="Team Logo URL"
                    value={formData.teamlogo}
                    onChange={handleInputChange}
                />
                <select
                    name="country"
                    value={formData.country}
                    onChange={handleCountryChange}
                >
                    <option value="">Select Country</option>
                    {Object.keys(countryOptions).map((country) => (
                        <option key={country} value={country}>
                            {country}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    name="season"
                    placeholder="Season"
                    value={formData.season}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="livery"
                    placeholder="Livery"
                    value={formData.livery}
                    onChange={handleInputChange}
                />
                <button type="submit">Add Entry</button>
            </form>

            <table className="items-table">
            <thead>
                <tr>
                    <th>Car Class</th> {/* Change from Class Logo to Car Class */}
                    <th>Team Logo</th>
                    <th>Car Number</th>
                    <th>Team Name</th>
                    <th>Car Make/Model</th>
                    <th>Drivers</th>
                    <th>Country</th>
                    <th>Season</th>
                    <th>Livery</th>
                    <th>Actions</th>
                </tr>
            </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td>{item.carclass}</td> {/* Change from item.classlogo to item.carclass */}
                            <td><img src={item.teamlogo} alt="Team Logo" /></td>
                            <td>{item.carnumber}</td>
                            <td>{item.teamname}</td>
                            <td>{item.carmakemodel}</td>
                            <td>{item.drivers.join(', ')}</td>
                            <td><img src={item.country} alt="Country Flag" /></td>
                            <td>{item.season}</td>
                            <td><img src={item.livery} alt="Livery" style={{ width: '50px', height: 'auto' }} /></td>
                            <td>
                                <button className="edit-button" onClick={() => handleEdit(item)}>Edit</button>
                                <button className="delete-button" onClick={() => confirmDelete(item.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this entry?</p>
                        <button className="delete-button" onClick={() => handleDelete(deleteItemId)}>Yes</button>
                        <button onClick={() => setShowModal(false)}>No</button>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Edit Entry</h3>
                        <form onSubmit={handleEditSubmit}>
                            <input
                                type="text"
                                name="teamname"
                                placeholder="Team Name"
                                value={editFormData.teamname}
                                onChange={handleEditInputChange}
                            />
                            <select
                                name="carclass"
                                value={editFormData.carclass}
                                onChange={handleEditClassChange}
                            >
                                <option value="">Select Class</option>
                                <option value="Hypercar">Hypercar</option>
                                <option value="LMP2">LMP2</option>
                                <option value="GT3">GT3</option>
                            </select>
                            <select
                                name="carmakemodel"
                                value={editFormData.carmakemodel}
                                onChange={handleEditInputChange}
                                disabled={!editFormData.carclass}
                            >
                                <option value="">Select Car Make/Model</option>
                                {getEditCarMakeModelOptions().map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="carnumber"
                                placeholder="Car Number"
                                value={editFormData.carnumber}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="drivers"
                                placeholder="Drivers (comma-separated)"
                                value={editFormData.drivers}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="teamlogo"
                                placeholder="Team Logo URL"
                                value={editFormData.teamlogo}
                                onChange={handleEditInputChange}
                            />
                            <select
                                name="country"
                                value={editFormData.country}
                                onChange={handleEditCountryChange}
                            >
                                <option value="">Select Country</option>
                                {Object.keys(countryOptions).map((country) => (
                                    <option key={country} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="season"
                                placeholder="Season"
                                value={editFormData.season}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="livery"
                                placeholder="Livery"
                                value={editFormData.livery}
                                onChange={handleEditInputChange}
                            />
                            <button className="edit-button" type="submit">Save</button>
                            <button type="button" onClick={() => setShowEditModal(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EntriesAdmin;