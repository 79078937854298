import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Schedule.css';

const Schedule = () => {
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [allRounds, setAllRounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch seasons and rounds
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch seasons
        const seasonsResponse = await axios.get('https://api.rfro-1000km.com/seasons');
        let seasonsData = seasonsResponse.data;

        // Sort seasons by season_number in descending order
        seasonsData = seasonsData.sort((a, b) => b.season_number - a.season_number);
        setSeasons(seasonsData);

        // Set the season with the highest season_number as selected
        const maxSeason = seasonsData[0];
        setSelectedSeason(maxSeason);

        // Fetch rounds
        const roundsResponse = await axios.get('https://api.rfro-1000km.com/rounds');
        setAllRounds(roundsResponse.data);

        // Filter and sort rounds if a season is already selected
        if (maxSeason) {
          const filteredRounds = roundsResponse.data
            .filter(round => round.season_id === maxSeason.id)
            .sort((a, b) => a.round_number - b.round_number); // Sort by round_number
          setRounds(filteredRounds);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter and sort rounds whenever selectedSeason changes
  useEffect(() => {
    if (selectedSeason) {
      const filteredRounds = allRounds
        .filter(round => round.season_id === selectedSeason.id)
        .sort((a, b) => a.round_number - b.round_number); // Sort by round_number

      // Find the next round and today's date
      const today = new Date().toISOString().split('T')[0];
      let nextRound = null;

      for (const round of filteredRounds) {
        if (round.date >= today) {
          nextRound = round;
          break;
        }
      }

      setRounds(filteredRounds.map(round => ({
        ...round,
        status: round.date < today ? 'past' : round === nextRound ? 'current' : 'future'
      })));
    }
  }, [selectedSeason, allRounds]);

  const handleOpen = (round) => {
    navigate(`/rounds/${round.id}`); // Navigate to round details page
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleSeasonChange = (event) => {
    const selectedSeasonId = parseInt(event.target.value, 10);
    const selectedSeason = seasons.find((season) => season.id === selectedSeasonId);
    setSelectedSeason(selectedSeason || null); // Set to null if not found
  };

  return (
    <div className="schedule-container">
      <h1>Schedule</h1>
      <div className="season-selector">
        <select className="season-select" onChange={handleSeasonChange} value={selectedSeason?.id || ''}>
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              Season {season.season_number}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <div className="spinner-container">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className="rounds-grid">
          {rounds.length > 0 ? (
            rounds.map((round) => (
              <div
                key={round.id}
                className={`round-card ${round.status}`}
              >
                <img src={round.image_url} alt={round.name} className="round-image" />
                <div className="round-info">
                  <h3>{round.name}</h3>
                  <p>{formatDate(round.date)}</p>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#ffd700', color: '#000' }}
                    onClick={() => handleOpen(round)}
                  >
                    More Info
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <p>No rounds available for the selected season.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Schedule;