// src/pages/Admin.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaCalendarAlt, FaNewspaper, FaTrophy, FaLifeRing, FaVideo } from 'react-icons/fa';
import './Admin.css'; // Import the CSS file for styling

const Admin = () => {
  return (
    <div className="admin-dashboard">
      <h1 className="dashboard-title">Admin Dashboard</h1>
      <div className="dashboard-cards">

        <div className="admin-card">
          <FaUsers className="card-icon" />
          <h2>Manage Entries</h2>
          <p>View, create, and manage entries across the platform.</p>
          <Link to="/admin/entries" className="card-link">Go to panel</Link>
        </div>

        <div className="admin-card">
          <FaCalendarAlt className="card-icon" />
          <h2>Manage Schedule</h2>
          <p>Update and maintain the racing schedule.</p>
          <Link to="/admin/schedule" className="card-link">Go to panel</Link>
        </div>

        <div className="admin-card">
          <FaNewspaper className="card-icon" />
          <h2>Manage Blog</h2>
          <p>Write and edit blog posts for the community.</p>
          <Link to="/admin/blog" className="card-link">Go to panel</Link>
        </div>

        <div className="admin-card">
          <FaTrophy className="card-icon" />
          <h2>Manage Results</h2>
          <p>Input and edit results for race and qualifying sessions.</p>
          <Link to="/admin/results" className="card-link">Go to panel</Link>
        </div>

        <div className="admin-card">
          <FaLifeRing className="card-icon" />
          <h2>Manage Support Tickets</h2>
          <p>View and manage support tickets created by users.</p>
          <Link to="/admin/support" className="card-link">Go to panel</Link>
        </div>

        <div className="admin-card">
          <FaVideo className="card-icon" />
          <h2>Manage Video</h2>
          <p>Upload and edit video files for the vod page.</p>
          <Link to="/wip" className="card-link">Go to panel</Link>
        </div>

      </div>
    </div>
  );
};

export default Admin;