// src/pages/Blog.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Blog.css'; // Import the CSS file for styling

const Blog = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://api.rfro-1000km.com/blog?status=published');
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };
    
        fetchPosts();
    }, []);

    // Function to strip HTML tags and truncate the text for the excerpt
    const createExcerpt = (htmlContent) => {
        const div = document.createElement('div');
        div.innerHTML = htmlContent;
        const textContent = div.innerText || div.textContent || '';
        return textContent.length > 150 ? textContent.substring(0, 150) + '...' : textContent;
    };

    return (
        <div className="blog">
            <h1>Blog</h1>
            <div className="blog-posts">
                {posts.map(post => (
                    <div key={post.id} className="blog-post">
                        <img src={post.thumbnail} alt={post.title} className="blog-thumbnail" />
                        <div className="blog-content">
                            <h2 className="blog-title">
                                <Link to={`/blog/${post.id}`}>{post.title}</Link>
                            </h2>
                            <p className="blog-subheading">{post.subheading}</p>
                            <p className="blog-excerpt">{createExcerpt(post.content)}</p>
                            <p className="blog-date">Posted on {new Date(post.date_posted).toLocaleDateString()}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;