import React from 'react';
import { Card, CardContent, Typography, CardActions, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AiOutlineArrowRight } from 'react-icons/ai';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: '20px',
  backgroundColor: '#1c1c1e',
  color: '#fff',
  borderRadius: '16px',
  border: '2px solid transparent',
  backgroundImage: 'linear-gradient(#1c1c1e, #1c1c1e), linear-gradient(to right, #fc0, #fc0)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
  },
}));

const StyledCardContent = styled(CardContent)({
  textAlign: 'left',
  padding: '20px',
});

const CustomIcon = styled('div')({
  fontSize: '40px',
  color: '#fc0',
  marginBottom: '10px',
});

const CustomCard = ({ icon, title, description, link }) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <CustomIcon>{icon}</CustomIcon>
        <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
          {title}
        </Typography>
        <Typography variant="body2" style={{ color: '#d1d1d6', margin: '10px 0' }}>
          {description}
        </Typography>
      </StyledCardContent>
      <CardActions style={{ justifyContent: 'flex-start', padding: '20px' }}>
        <Link href={link} underline="none" style={{ display: 'flex', alignItems: 'center', color: '#d1d1d6' }}>
          <AiOutlineArrowRight style={{ fontSize: '24px' }} />
        </Link>
      </CardActions>
    </StyledCard>
  );
};

export default CustomCard;