// src/pages/VideoAdmin.js
import React from 'react';
import '../App.css';

const VideoAdmin = () => (
  <div className="container">
    <h1>Video Admin</h1>
    <p>This feature is currently unavailable. Stay tuned for more updates.</p>
  </div>
);

export default VideoAdmin;