import React from 'react';
import './Error404.css'; // This is where you'll style the page

const Error404 = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h1 className="error-code">404</h1>
        <p className="error-message">Oops! The page you're looking for doesn't exist.</p>
      </div>
    </div>
  );
};

export default Error404;