import React, { useState } from 'react';
import { FaDiscord } from 'react-icons/fa';
import './SupportTicketForm.css';

const questions = [
  {
    id: 'first_name',
    question: '1. What is your first name?',
    type: 'text',
    placeholder: 'Enter your first name'
  },
  {
    id: 'contact_method',
    question: '2. How can we contact you?',
    type: 'radio',
    options: ['Discord']
  },
  {
    id: 'discord_username',
    question: '3. What is your Discord username?',
    type: 'text',
    placeholder: 'Enter your Discord username'
  },
  {
    id: 'issue_type',
    question: '4. What can we help you with?',
    type: 'radio',
    options: [
      'Something is broken',
      'I need help with my account',
      'Incorrect/missing entry list info',
      'I have a feature idea',
      'Other'
    ]
  },
  {
    id: 'additional_info',
    question: '5. Please tell us more',
    type: 'textarea',
    placeholder: 'Provide additional information'
  },
  {
    id: 'version',
    question: '6. Which version are you using?',
    type: 'radio',
    options: [
      'Stable - If unsure, pick this one!',
      'Beta'
    ]
  }
];

const SupportTicketForm = () => {
  const [form, setForm] = useState({});
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'radio') {
      setForm({ ...form, [name]: value });
    } else if (type === 'checkbox') {
      setForm({
        ...form,
        [name]: checked
          ? [...(form[name] || []), value]
          : (form[name] || []).filter((item) => item !== value)
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleCardClick = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("Submitting form:", form); // Log form data for debugging
    try {
      // Send form data to the API
      const response = await fetch('https://api.rfro-1000km.com/support-tickets', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
      });

      // Check if form submission was successful
      if (response.ok) {
        alert('Ticket submitted successfully!');
        setForm({});

        // Now send the data to the Discord webhook
        const webhookURL = 'https://discord.com/api/webhooks/1288835758945337426/nTfkzlRoiH6pCmfLaJZlpHvEk6XX-tdpv-SZXv95XxJNKSv1-Aa6salEp4iRL_7mym9G'; // Replace with your Discord webhook URL
        const embed = {
          title: 'New Support Ticket',
          fields: [
            { name: 'First Name', value: form.first_name || 'N/A', inline: true },
            { name: 'Contact Method', value: form.contact_method || 'N/A', inline: true },
            { name: 'Discord Username', value: form.discord_username || 'N/A', inline: true },
            { name: 'Issue Type', value: form.issue_type || 'N/A', inline: true },
            { name: 'Additional Info', value: form.additional_info || 'N/A', inline: false },
            { name: 'Version', value: form.version || 'N/A', inline: true }
          ],
          timestamp: new Date().toISOString(),
          color: 15548997 // Optional: color code for the embed (Discord uses decimal format)
        };

        // Send the embed to the Discord webhook
        await fetch(webhookURL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ embeds: [embed] })
        });

      } else {
        setError('Failed to submit the ticket. Please try again.');
      }
    } catch {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="support-ticket-form">
      <h1>Submit a Support Ticket</h1>
      <form onSubmit={handleSubmit}>
        {questions.map((question, index) => (
          <div className="form-section" key={index}>
            <label htmlFor={question.id}>{question.question}</label>
            {question.type === 'text' && (
              <input
                type="text"
                id={question.id}
                name={question.id}
                value={form[question.id] || ''}
                onChange={handleChange}
                placeholder={question.placeholder}
                required
                aria-label={question.question}
                className="support-text-field"
              />
            )}
            {question.type === 'textarea' && (
              <textarea
                id={question.id}
                name={question.id}
                value={form[question.id] || ''}
                onChange={handleChange}
                placeholder={question.placeholder}
                required
                aria-label={question.question}
                className="support-text-field"
              />
            )}
            {question.type === 'radio' && (
              <div className="options-container">
                {question.options.map((option, index) => (
                  <div
                    key={index}
                    className={`card ${form[question.id] === option ? 'selected' : ''}`}
                    onClick={() => handleCardClick(question.id, option)}
                    aria-label={option}
                    role="button"
                    tabIndex={0}
                  >
                    {option === 'Discord' ? (
                      <div className="icon-card">
                        <FaDiscord size={24} />
                      </div>
                    ) : (
                      <label>{option}</label>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="form-navigation">
          <button type="submit" className="submit-button">
            Submit
          </button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default SupportTicketForm;