// src/pages/BlogArticle.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BlogArticle.css'; // Import the CSS file for styling

const BlogArticle = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://api.rfro-1000km.com/blog/${id}`);
                setPost(response.data);
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };

        fetchPost();
    }, [id]);

    if (!post) return <p>Loading...</p>;

    return (
        <div className="blog-article">
            <h1>{post.title}</h1>
            <div className="blog-tags">
                {post.tags.map((tag, index) => (
                    <span key={index} className="blog-tag">{tag}</span>
                ))}
            </div>
            <p className="blog-author">By {post.author}</p>
            <p className="blog-date">Posted on {new Date(post.date_posted).toLocaleDateString()}</p>
            <img src={post.main_image} alt={post.title} className="blog-main-image" />
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
    );
};

export default BlogArticle;