import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import './Rulebook.css';

const rulebookContent = {
  EN: {
    title: "Rulebook",
    sections: {
      0: "Updates",
      1: "Overview",
      2: "Registrations",
      3: "Cars",
      4: "Pre-Race",
      5: "Teamspeak Server",
      6: "Points Distribution",
      7: "Car Skin",
      8: "Server Settings",
      9: "Race Schedule",
      10: "Weather",
      11: "Qualification",
      12: "Race",
      13: "Race Chat",
      14: "Code 80",
      15: "Incidents and Complaints",
      16: "Sanctions",
      17: "Broadcast",
      18: "Server Division",
      19: "Promotion and Demotion"
    },
    content: {
      0: {
        title: "Updates",
        content: [
          {
            text: "Any changes to the rules will be announced here as well as updated in the rules below."
          }
        ]
      },
      1: {
        title: "1. Overview",
        content: [
          {
            text: "The championship is hosted by the Racingfr.net (RFRO) league. This is an open championship that does not require league membership. Season 5 of the 1000km Series will feature 7 rounds from October 2024 to June 2025. The HYPERCAR, LMP2 and GT3 classes have been selected for this season. Find all the details on the championship discord and dedicated website. "
          }
        ]
      },
      2: {
        title: "2. Registrations",
        content: [
          {
            subsection: "2.1",
            text: "Team registration must be completed on the Discord entry-list-season-5 channel.",
            subpoints: [
              {
                subsection: "2.1.1",
                text: "Each driver must join the championship Discord server and will be assigned a specific role."
              }
            ]
          },
          {
            subsection: "2.2",
            text: "Use of pseudonyms and team tags is prohibited. Real Last Name/First Name must be used.",
            subpoints: [
              {
                text: "The Last Name and First Name must be consistent throughout the championship and match the registration details."
              },
              {
                text: "Special characters (é,è,ç,Cyrillic, etc.) are not supported in-game. Do not use them in names."
              },
              {
                text: "Any violation of this rule will result in team disqualification for the race."
              },
              {
                text: `[RFRO] Jacquete Damien\nJack_7\nJacquete Damien`,
                preformatted: true
              }
            ]
          },
          {
            subsection: "2.3",
            text: "The registration must follow this format:",
            subpoints: [
              {
                text: `Team name:\nTeam country:\nNumber:\nCategory:\nCar:\nTeam Manager: Last Name / First Name / @DiscordID\nDriver 1: Last Name / First Name / @DiscordID\nDriver 2: Last Name / First Name / @DiscordID\nDriver 3: Last Name / First Name / @DiscordID\nTeam logo: (Required) `,
                preformatted: true
              }
            ]
          },
          {
            subsection: "2.4",
            text: "Team managers are free to build up their teams as they wish over the course of the season. This allows them to add several drivers to their team.",
          },
          {
            subsection: "2.5",
            text: "Car selection is final for the entire season.",
            subpoints: [
              {
                text: `Any car change will result in the total loss of all points previously accumulated in the championship. `,
              }
            ]
          },
          {
            subsection: "2.6",
            text: "Drivers may not be exchanged between teams, except on a permanent basis. As an example, a team manager with 2 RFRO LMP2 / RFRO GT teams is free to compose their crews among all the drivers registered under the RFRO structure. If one of the registered drivers moves to another team in the championship, they will not be able to return to the RFRO structure afterwards.",
          },
          {
            subsection: "2.7",
            text: "Minimum number of drivers per car per race is 2, maximum 3.",
          },
          {
            subsection: "2.8",
            text: "A driver cannot race in 2 teams at the same time, even if they belong to the same structure.",
          },
          {
            subsection: "2.9",
            text: "As the championship is open, you can join at any time. However, to take part in the race, you must register at least 15 days before the race. Any registration made after this deadline will result in registration for the following race.",
          },
        ]
      },
      3: {
        title: "3. Cars",
        content: [
          {
            subsection: "3.1",
            text: "The following cars have been selected for Season 5:",
            subpoints: [
              {
                text: "Hypercar = Ferrari 499P, Porsche 963\nLMP2 = Oreca 07\nGT3 = Aston Martin Vantage GT3 2019, Bentley Continental GT3 2020, BMW M4 GT3 2020, Ferrari 488 GT3 2020, McLaren 720S GT3 2018, Mercedes AMG GT3 2017, Porsche 911 GT3R 2018",
                preformatted: true
              }
            ]
          },
          {
            subsection: "3.2",
            text: "Cars will have set configurations:",
            subpoints: [
              {
                text: "Hypercar: FIA WEC tire warmer, Le Mans aero package, 520kW power\nOreca 07: Le Mans aero package, -81CV power, +20KG weight\nGT3: N/A",
                preformatted: true
              }
            ]
          },
          {
            subsection: "3.3",
            text: "Tire change time (x4) reduced to 35s for all cars",
          }
        ]
      },
      4: {
        title: "4. Pre-Race",
        content: [
          {
            subsection: "4.1",
            text: "Before each race, an entry list will be created so that you can manage your participation for the race (e.g. #entry-list-rd1-bahrain). You can declare whether or not you'll take part by typing the car number, team name and either a check mark or cross. You must manage your participation 7 days before the race. If you do not, your slot in the race will not be guaranteed."
          }
        ]
      },
      5: {
        title: "5. Teamspeak Server",
        content: [
          {
            subsection: "5.1",
            text: "On race day, we'll be using our Teamspeak servers for team audio communication. Each team has its own room."
          },
          {
            subsection: "5.2",
            text: "Users will use the same Last Name / First Name as in rF2."
          },
          {
            subsection: "5.3",
            text: "Drivers must be in their team's Teamspeak room for the whole of their stint."
          },
          {
            subsection: "5.4",
            text: "Any team failing to be present on Teamspeak at the end of qualifying will be disqualified. A check will be made during the session."
          },
          {
            subsection: "5.5",
            text: "Login information for our Teamspeak servers is available in the Discord."
          }
        ]
      },
      6: {
        title: "6. Points Distribution",
        content: [
          {
            text: "Currently unavailable."
          }
        ]
      },
      7: {
        title: "7. Car Skin",
        content: [
          {
            subsection: "7.1",
            text: "Car decoration is mandatory."
          },
          {
            subsection: "7.2",
            text: "You can download the template for your car on the website."
          },
          {
            subsection: "7.3",
            text: "Number plates are already included and no modifications arte allowed (e.g. position, size, font, colours). Any modifications will result in a refusal of the skin. Only your car number can be changed."
          },
          {
            subsection: "7.4",
            text: "Chrome is not allowed on the front and rear of the car."
          },
          {
            subsection: "7.5",
            text: "Windshield band is reserved for the organisation."
          },
          {
            subsection: "7.6",
            text: "The following files are required for your skin:",
            subpoints: [
              {
                text: ".dds (car)\n.dds (region)\n.json",
                preformatted: true
              }
            ]
          },
          {
            subsection: "7.7",
            text: "Files should be named as follows:",
            subpoints: [
              {
                text: "From the template provided, simply replace the XXX with your car number\nALT_\nCar class (HY/P2/GT)\nFirst 3 characters of the car brand (except for LMP2s)\nCar number\nExample: ALT_HY_POR_222.dds",
                preformatted: true
              }
            ]
          },
          {
            subsection: "7.8",
            text: "Skins can be uploaded via the website."
          },
          {
            subsection: "7.9",
            text: "Skins must be supplied to the organisation 15 days before the race date (except for the first race, where the deadline is 21/09/2024). Any skins supplied after this deadline will not be accepted for the race and you will be prevented from taking part in the race."
          },
          {
            subsection: "7.10",
            text: "All skins will be packed into a skinpack which you can download from the Steam Workshop (or via the website)."
          },
        ]
      },
      8: {
        title: "8. Server Settings",
        content: [
          {
            subsection: "8.1",
            text: "Practice servers (dry and rain/night) will be available the day after the race for the next one."
          },
          {
            subsection: "8.2",
            text: "Practice server settings:",
            subpoints: [
              {
                text: "Fuel consumption = x1\nTire wear = x1\nDamage = 80%\nTrack rubber = normal/heavy",
                preformatted: true
              }
            ]
          },
          {
            subsection: "8.3",
            text: "Race server settings:",
            subpoints: [
              {
                text: "Fuel consumption = x1\nTire wear = x1\nDamage = 80%\nTrack rubber for qualifying = normal/heavy\nTrack rubber for race = naturally progressing",
                preformatted: true
              }
            ]
          }
        ]
      },
      9: {
        title: "9. Race Schedule",
        content: [
          {
            subsection: "9.1",
            text: "Times are expressed in UTC format."
          },
          {
            subsection: "9.2",
            text: "To find out the time zone corresponding to your own country/region, you can easily convert the UTC into your own using any internet search engine."
          },
          {
            subsection: "9.3",
            text: "Times:",
            subpoints: [
              {
                text: "Briefing = 10:15\nRace server opening = 10:30\nQualifying = 10:45\nWarmup = 11:50\nRace = 12:00",
                preformatted: true
              }
            ]
          }
        ]
      },
      10: {
        title: "10. Weather",
        content: [
          {
            subsection: "10.1",
            text: "The weather will be managed by the rF2WeatherPlugin plugin. Weather conditions may be adjusted by the organisation (for racing purpose)."
          }
        ]
      },
      11: {
        title: "11. Qualification",
        content: [
          {
            subsection: "11.1",
            text: "Qualifying takes place in public mode."
          },
          {
            subsection: "11.2",
            text: "One qualifying session for each category (excluding Le Mans)"
          },
          {
            subsection: "11.3",
            text: "Each car must be on the server at the start of qualifying and remain on the server until the end of the qualifying session."
          },
          {
            subsection: "11.4",
            text: "Qualifying sessions for each category will last 15 minutes."
          },
          {
            subsection: "11.5",
            text: "Session timetable:",
            subpoints: [
              {
                text: "10:15: Qualifying server opens (5 minute delay for all cars to enter the server)\n10:50: GT3 green flag\n11:05: GT3 end of session (Will be announced by Teamspeak whisper. The lap in progress can be completed).\n11:10: LMP2 green flag\n11:25: LMP2 end of session (Will be announced by Teamspeak whisper. The lap in progress can be completed).\n11:30: Hypercar green flag\nThe end of the session will be managed by the game.",
                preformatted: true
              }
            ]
          },
          {
            subsection: "11.6",
            text: "Le mans race schedule to be adjusted."
          },
          {
            subsection: "11.7",
            text: "Any violation of the schedules will result in disqualification from the session."
          },
          {
            subsection: "11.8",
            text: "Use of the ESC key is allowed. To be used only when your car is off track."
          }
        ]
      },
      12: {
        title: "12. Race",
        content: [
          {
            subsection: "12.1",
            text: "The driver who starts the race must be the same as the driver who qualifies."
          },
          {
            subsection: "12.2",
            text: "The race start will be staggered between each class."
          },
          {
            subsection: "12.3",
            text: "The formation lap pace will be set by the driver in pole position in their class. The laps speed will be 160 km/h for prototypes and 120/kmh for GT3s. The formation lap will be run in single file. Braking in a straight line is forbidden. The polesitter in the LMP2 and GT3 categories will ensure that they leave a gap of around 10 seconds to the last car in the class ahead. On approaching the start zone, cars move into double columns. The polesitter of each class is free to choose when to start the race, but this must be within the start zone (will be available in in the Discord). Any start before this zone will be penalised. It is forbidden to overtake cars in the same class before the start line."
          },
          {
            subsection: "12.4",
            text: "During pit stops, it is strictly forbidden to push other cars in order to make room for yourself."
          },
          {
            subsection: "12.5",
            text: "At the end of the race, you must complete a cool down lap in order to return to the pits. It is therefore forbidden to throw your car into a competitor or a wall. The aim of this is to ensure that the end of the race can be broadcast by the race broadcasters."
          },
          {
            subsection: "12.6",
            text: "A maximum of one spotter per team is allowed on the race server.",
          }
        ]
      },
      13: {
        title: "13. Race Chat",
        content: [
          {
            subsection: "13.1",
            text: "Use of in-game text chat is forbidden during qualifying and race sessions, even to say sorry."
          },
          {
            subsection: "13.2",
            text: "The use of chat is strictly reserved for the organisation."
          },
          {
            subsection: "13.3",
            text: "Failure to comply with the chat rules will result in a penalty at the discretion of race control."
          }
        ]
      },
      14: {
        title: "14. Code 80",
        content: [
          {
            subsection: "14.1",
            text: "Code 80 is a procedure which allows race control to impose a pit limiter of 80km/h on all cars on the track. It is triggered in the event of a serious accident, to prevent further accidents by allowing the damaged vehicle(s) to return safely to the pits for repairs."
          },
          {
            subsection: "14.2",
            text: "The triggering and ending of a Code 80 are carried out on Teamspeak at the discretion of race control via a whisper."
          },
          {
            subsection: "14.3",
            text: "When Code 80 is implemented, tyhere is a 10 second countdown before the speed limit comes into effect. During this time, it is up to each driver to slow down safely so as not to cause an accident with the other cars. Overtaking during the 10 second countdown is strictly forbidden, regardless of class."
          },
          {
            subsection: "14.4",
            text: "At the end of the countdown, Code 80 comes into effect. It is forbidden to release the pit limiter and it is forbidden to overtake another driver, with the exception of the car(s) involved in the accident.",
            subpoints: [
              {
                subsection: "14.4.1",
                text: "Cars involved in the accident(s) must return to the pits."
              }
            ]
          },
          {
            subsection: "14.5",
            text: "Failure to comply with the procedure will result in a penalty."
          },
          {
            subsection: "14.6",
            text: "If your team's car is involved in a serious enough accident that the return to the pits cannot be made without risk to your own car or to the other ones, you are invited to come to the stewards' room to ask for a Code 80 to be issued immediately. After a quick analysis, Code 80 may be issued. Typical accidents covered by this procedure involve any impact which results in the loss of most of the vehicle's control, such as the loss of a wheel or rear wing."
          },
          {
            subsection: "14.7",
            text: "Pits remain open throughout the Code 80 procedure, except when indicated by race control."
          }
        ]
      },
      15: {
        title: "15. Incidents and complaints",
        content: [
          {
            subsection: "15.1",
            text: "Any driver who feels they have suffered prejudice at the hands of another driver is encouraged to file a complaint with race control."
          },
          {
            subsection: "15.2",
            text: "Any driver wishing to file a complaint must communicate the following to race control:",
            subpoints: [
              {
                text: "Car number\nLap on which the icident occurred\nNumber of the offending car.",
                preformatted: true
              }
            ]
          },
          {
            subsection: "15.3",
            text: "For each complaint, a decision wil be taken by the organisation, which is final and without grounds for appeal."
          },
          {
            subsection: "15.4",
            text: "A period of 48 hours after the end of the race allows you to submit a complaint to race control via the ticket channel on the Discord. The same information will be requested as in 15.2.",
          },
          {
            subsection: "15.5",
            text: "Complaints should be made via the ticket tool on the Discord."
          },
          {
            subsection: "15.6",
            text: "Complaints sent by private message to a steward will not be examined by race control."
          }
        ]
      },
      16: {
        title: "16. Sanctions",
        content: [
          {
            subsection: "16.1",
            text: "Below are the various sanctions available to the stewards:",
            subpoints: [
              {
                text: "Give back a position\nWarning\nAdd 5s to race time\nAdd 10s to race time\nDrive-through\nStop and Go 10s-60s\nDisqualification",
                preformatted: true
              }
            ]
          },
          {
            subsection: "16.2",
            text: "You will be notified of ever decision resulting in a penalty via the in-game chat."
          },
          {
            subsection: "16.3",
            text: "If a car receives a third warning during the race, this will be converted into a drive-through.",
          }
        ]
      },
      17: {
        title: "17. Broadcast",
        content: [
          {
            subsection: "17.1",
            text: "Qualifying and the race will be broadcast live by our partners."
          },
          {
            subsection: "17.2",
            text: "An interview session will be set up at the end of the race, either vocally by the broadcaster(s) or in writing by the organisation."
          },
          {
            subsection: "17.3",
            text: "Interviews will be conducted in the broadcaster's Teamspeak room, Discord VC or in private messages via Discord."
          },
          {
            subsection: "17.4",
            text: "During the race, interviews with spotters may be conducted to inform spectators.",
          }
        ]
      },
      18: {
        title: "18. Server Division",
        content: [
          {
            subsection: "18.1",
            text: "Teams will be allocated to each server via a qualifying hotlap. From 25/09/2024 to 05/10/2024 (22:00) a qualifying server will be open. The best time of the 2 fastest drivers per car will be taken and an average of these 2 times will give the qualifying time.",
            subpoints: [
              {
                text: "Car #01:\nDriver 1: 1.40.00\nDriver 2: 1.41.00\nQualifying time for Car #01: 1.40.50",
                preformatted: true
              }
            ]
          },
          {
            subsection: "18.2",
            text: "The team line-up must be the same as was specified at the time of registration. Minimum 2 drivers, maximum 3."
          },
          {
            subsection: "18.3",
            text: "The car used for qualifying will be the car used for the rest of the season.",
          },
          {
            subsection: "18.4",
            text: "If one of the two drivers does not set a time, the average time will not be taken."
          }
        ]
      },
      19: {
        title: "19. Promotion and Demotion",
        content: [
          {
            subsection: "19.1",
            text: "Promotions and demotions will be carried out throughout the season to balance the divisions."
          },
          {
            subsection: "19.2",
            text: "Promotions and demotions will take place after races 3 and 6."
          },
          {
            subsection: "19.3",
            text: "The top 3 cars in each class will be promoted. The bottom 3 will be demoted.",
          },
          {
            subsection: "19.4",
            text: "Promotions and demotions will be based on the following:",
            subpoints: [
              {
                text: "Promotions and demotions after the first 3 races based on the results of races 1, 2 and 3.\nPromotions and demotions after race 6 based on the results of races 4, 5 and 6 (races 1, 2 and 3 will not be taken into account).",
                preformatted: true
              }
            ]
          },
        ]
      },
    }
  }
};

const RulebookSection = ({ section }) => {
  return (
    <div className="rulebook-section">
      <h3>{section.title}</h3>
      {section.content.map((subSection, index) => {
        const currentSubsectionNumber = subSection.subsection; // e.g., "2.1"

        return (
          <div key={index} className="rulebook-subsection">
            <p>
              <strong>{currentSubsectionNumber}</strong> {subSection.text}
            </p>

            {/* Render subpoints, if available */}
            {subSection.subpoints && (
              <div className="rulebook-subpoints">
                {subSection.subpoints.map((point, idx) => {
                  const currentSubpointNumber = `${currentSubsectionNumber}.${idx + 1}`;
                  return (
                    <div key={idx} className="rulebook-subpoint">
                      {point.preformatted ? (
                        <pre>{point.text}</pre> // No numbering for preformatted text
                      ) : (
                        <p>
                          {currentSubpointNumber} {point.text}
                        </p> // Include numbering for non-preformatted text
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Rulebook = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('EN');

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const selectedRulebook = rulebookContent[selectedLanguage];

  return (
    <div className="rulebook-container">
      <div className="rulebook-header">
        <h1>{selectedRulebook.title}</h1>
        <div className="language-selector">
          <label htmlFor="language">Select Language:</label>
          <select id="language" value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="EN">English</option>
            {/* Add options for other languages */}
          </select>
        </div>
      </div>
      <Alert variant="filled" severity="warning">Currently only available in English.</Alert>

      <div className="rulebook-content">
        <div className="rulebook-toc">
          <h2>Table of Contents</h2>
          <ul>
            {Object.entries(selectedRulebook.sections).map(([key, value]) => (
              <li key={key}>
                <a href={`#${key}`}>{value}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="rulebook-sections">
          {Object.entries(selectedRulebook.content).map(([key, section], index) => (
            typeof section === 'string' ? (
              // Add an id to this section div
              <div key={index} id={key}>
                <p>{section}</p>
              </div>
            ) : (
              // Pass the id prop down if needed in RulebookSection
              <div key={index} id={key}>
                <RulebookSection section={section} />
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rulebook;