import React from 'react';
import CardGrid from '../components/CardGrid';

import '../App.css';

const Servers = () => (
  <div className="container">
    <h1>Servers</h1>
    <p>The links will not work if rFactor 2 is open. To join the server, please first close rFactor 2.</p>
    <CardGrid />
  </div>
);

export default Servers;