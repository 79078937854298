// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Error404 from './components/Error404';
import Unauthorised from './pages/Unauthorised';
import InProgress from './pages/InProgress';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Schedule from './pages/Schedule';
import Teams from './pages/Teams';
import Team from './pages/Team';
import LivePage from './pages/Live';
import Results from './pages/Results';
import Servers from './pages/Servers';
import Modpack from './pages/Modpack';
import Rulebook from './pages/Rulebook';
import Blog from './pages/Blog';
import BlogArticle from './pages/BlogArticle';
import Login from './pages/Login';
import Admin from './pages/Admin';
import EntriesAdmin from './pages/EntriesAdmin';
import BlogAdmin from './pages/BlogAdmin';
import ScheduleAdmin from './pages/ScheduleAdmin';
import ResultsAdmin from './pages/ResultsAdmin';
import VideoAdmin from './pages/VideoAdmin';
import SupportAdmin from './pages/SupportAdmin';
import RaceDetails from './pages/RaceDetails';
import Skins from './pages/Skins';
import SupportTicketForm from './pages/SupportTicketForm';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/unauthorised" element={<Unauthorised />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/teams/:id" element={<Team />} />
          <Route path="/live" element={<LivePage />} />
          <Route path="/results" element={<Results />} />
          <Route path="/servers" element={<Servers />} />
          <Route path="/modpack" element={<Modpack />} />
          <Route path="/rules" element={<Rulebook />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogArticle />} />
          <Route path="/skins" element={<Skins />} />
          <Route path="/video" element={<InProgress />} />
          <Route path="/video/watchlist" element={<InProgress />} />
          <Route path="/rounds/:id" element={<RaceDetails />} />
          <Route path="/support" element={<SupportTicketForm />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/wip" element={<InProgress />} />

          {/* Admin routes */}
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute element={<Admin />} requiredRole={3} /> // Assuming 3 is the role_id for Admin
            } 
          />
          <Route 
            path="/admin/entries" 
            element={
              <ProtectedRoute element={<EntriesAdmin />} requiredRole={3} />
            } 
          />
          <Route 
            path="/admin/blog" 
            element={
              <ProtectedRoute element={<BlogAdmin />} requiredRole={3} />
            } 
          />
          <Route 
            path="/admin/schedule" 
            element={
              <ProtectedRoute element={<ScheduleAdmin />} requiredRole={3} />
            } 
          />
          <Route 
            path="/admin/results" 
            element={
              <ProtectedRoute element={<ResultsAdmin />} requiredRole={3} />
            } 
          />
          <Route 
            path="/admin/video" 
            element={
              <ProtectedRoute element={<VideoAdmin />} requiredRole={3} />
            } 
          />
          <Route 
            path="/admin/support" 
            element={
              <ProtectedRoute element={<SupportAdmin />} requiredRole={3} />
            } 
          />

          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </Router>
    </AuthProvider>
  );
}

export default App;